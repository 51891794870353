import * as React from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import Swal from 'sweetalert2';
import "./View.css";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { FaDownload, FaCertificate, FaCheck } from "react-icons/fa";
import * as QueryString from "query-string";
import { verifyPDF } from './verify-pdf';
import { FiRotateCw } from 'react-icons/fi';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/display/api';
//const { verifyPDF } = require('./verify-pdf');

const PDFThumnail = React.lazy(() => import('./PDFThumnail'));

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
};

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

class PDFView extends React.Component<any, {
    filePath: string,
    params: string,
    readOnly: boolean,
    numPages: number,
    pageNumber: number,
    scaleIndex: number,
    scaleList: number[],
    defaultPageRotates: number[],
    base64PDF: string | ArrayBuffer | null,
    isSigned: boolean,
    showSigned: boolean,
    signedBy: string,
    signedEmail: string,
    signedOn: string,
    rotation: number
}> {
    constructor(props: any) {
        super(props);

        this.state = {
            filePath: '',
            params: '',
            readOnly: false,
            numPages: 0,
            pageNumber: 1,
            scaleIndex: 7,
            scaleList: [0.25, 0.33, 0.5, 0.67, 0.75, 0.80, 0.90, 1, 1.11, 1.25, 1.50, 1.75, 2.0, 2.5, 3, 4, 5],
            defaultPageRotates: [],
            base64PDF: null,
            isSigned: false,
            showSigned: true,
            signedBy: '',
            signedEmail: '',
            signedOn: '',
            rotation: 0
        }

        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.onClickGoToPreviousPage = this.onClickGoToPreviousPage.bind(this);
        this.onClickGoToNextPage = this.onClickGoToNextPage.bind(this);
        this.onClickScaleDown = this.onClickScaleDown.bind(this);
        this.onClickScaleUp = this.onClickScaleUp.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);
        this.onClickSignedBadge = this.onClickSignedBadge.bind(this);
    }

    componentDidMount() {
        var { filePath } = this.props
        let params = QueryString.parse(filePath.substr(filePath.lastIndexOf('?') + 1))
        var readOnly = false
        if (params.read_only) {
            if (String(params.read_only).toLowerCase() == 'true') {
                readOnly = true
            }
        }

        fetch(filePath,
            {
                method: "GET"
            })
            .then(response => {
                if (response.ok) {
                    return response.blob()
                }
                throw new Error('Something went wrong');
            })
            .then(response => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(response);
                reader.onloadend = () => {
                    var result = reader.result
                    //console.log(verifyPDF)
                    const verified = verifyPDF.verifyPDF(result);
                    var isSigned = false
                    var signedBy = ''
                    var signedEmail = ''
                    var signedOn = ''
                    if (!verified.message) {
                        var certs = verified.meta?.certs
                        for (let i = 0; i < certs.length; i++) {
                            if (certs[i].clientCertificate) {
                                //console.log(certs[i])
                                var issuedTo = certs[i].issuedTo["commonName"]
                                var fixedstring = decodeURIComponent(escape(issuedTo));
                                isSigned = true
                                signedBy = fixedstring
                                signedEmail = ''
                                signedOn = verified.lastModify

                                break;
                            }
                        }
                    }
                    this.setState({
                        filePath: filePath,
                        base64PDF: result,
                        readOnly: (readOnly != undefined) ? readOnly : false,
                        isSigned: isSigned,
                        signedBy: signedBy,
                        signedEmail: signedEmail,
                        signedOn: signedOn
                    })
                }
            })
            .catch((error) => {
                this.onLoadError();
            });
    }

    componentDidUpdate() {
        let { numPages, pageNumber, scaleIndex, scaleList } = this.state
        if (pageNumber == 1) {
            var btnPreviousPage = document.getElementById('btnPreviousPage') as HTMLButtonElement;
            if ((btnPreviousPage != undefined) && (btnPreviousPage.disabled == false)) {
                btnPreviousPage.disabled = true;
            }
        } else {
            var btnPreviousPage = document.getElementById('btnPreviousPage') as HTMLButtonElement;
            if ((btnPreviousPage != undefined) && (btnPreviousPage.disabled == true)) {
                btnPreviousPage.disabled = false;
            }
        }

        if (pageNumber == numPages) {
            var btnNextPage = document.getElementById('btnNextPage') as HTMLButtonElement;
            if ((btnNextPage != undefined) && (btnNextPage.disabled == false)) {
                btnNextPage.disabled = true;
            }
        } else {
            var btnNextPage = document.getElementById('btnNextPage') as HTMLButtonElement;
            if ((btnNextPage != undefined) && (btnNextPage.disabled == true)) {
                btnNextPage.disabled = false;
            }
        }

        if (scaleIndex == 0) {
            var btnScaleDown = document.getElementById('btnScaleDown') as HTMLButtonElement;
            if ((btnScaleDown != undefined) && (btnScaleDown.disabled == false)) {
                btnScaleDown.disabled = true;
            }
        } else {
            var btnScaleDown = document.getElementById('btnScaleDown') as HTMLButtonElement;
            if ((btnScaleDown != undefined) && (btnScaleDown.disabled == true)) {
                btnScaleDown.disabled = false;
            }
        }

        if (scaleIndex == (scaleList.length - 1)) {
            var btnScaleUp = document.getElementById('btnScaleUp') as HTMLButtonElement;
            if ((btnScaleUp != undefined) && (btnScaleUp.disabled == false)) {
                btnScaleUp.disabled = true;
            }
        } else {
            var btnScaleUp = document.getElementById('btnScaleUp') as HTMLButtonElement;
            if ((btnScaleUp != undefined) && (btnScaleUp.disabled == true)) {
                btnScaleUp.disabled = false;
            }
        }

        // setTimeout(this.adjustScrollSpace, 0);
    }

    adjustScrollSpace = () => {
        const pdf = document.querySelector<HTMLCanvasElement>('.selected-document .react-pdf__Page__canvas');
        if (!pdf) {
            return;
        }

        const clientRect = pdf.getBoundingClientRect();
        if (clientRect.y < 0) {
            pdf.style.transform = `translateY(${-1 * clientRect.y}px)`;
        }
    }

    async onDocumentLoadSuccess(pdf: PDFDocumentProxy) {
        // Get default page rotates
        const defaultPageRotates: number[] = []
        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            defaultPageRotates.push(page.rotate);
        }

        this.setState({
            numPages: pdf.numPages,
            defaultPageRotates
        });
    }

    onDocumentLoadError() {
        this.onLoadError()
    }

    onLoadError() {
        Swal.fire({
            title: 'ไม่สำเร็จ!',
            text: 'ไม่พบข้อมูล หรือไม่ได้รับสิทธิ์ให้เข้าดูไฟล์ กรุณาติดต่อผู้ดูแลระบบ',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ยืนยัน!'
        }).then((result) => {
            if (result.value) {
            }

            this.setState({
                filePath: '',
                base64PDF: '',
                readOnly: true
            })
        })
    }

    changePage = (pageNumber: number) => {
        this.setState({ pageNumber });
    };

    onClickPageThumnail(pageNumber: number) {
        this.changePage(pageNumber);
    }

    onClickGoToPreviousPage() {
        const { pageNumber } = this.state;
        if (pageNumber > 1) {
            this.changePage(pageNumber - 1);
        }
    }

    onClickGoToNextPage() {
        const { numPages, pageNumber } = this.state;
        if (pageNumber < numPages) {
            this.changePage(pageNumber + 1);
        }
    }

    onClickScaleUp() {
        let { scaleIndex, scaleList } = this.state
        if (scaleIndex < scaleList.length - 1) {
            this.setState({
                scaleIndex: scaleIndex + 1
            })
        }
    }

    onClickScaleDown() {
        let { scaleIndex } = this.state
        if (scaleIndex > 0) {
            this.setState({
                scaleIndex: scaleIndex - 1
            })
        }
    }

    downloadFile(filePath) {
        fetch(filePath, {
            method: 'GET',
        }).then(function (resp) {
            return resp.blob();
        }).then(function (blob) {
            const newBlob = new Blob([blob], { /*type: "application/pdf", charset: "UTF-8"*/ })

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);

                return;
            }
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            //link.dataType = "json";
            link.href = data;
            link.download = filePath.substr(filePath.lastIndexOf('file_name=') + 10);
            link.dispatchEvent(new MouseEvent('click'));
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
            }, 60);
        });
    }

    onClickDownload() {
        let { filePath, params } = this.state
        //params += '&download=true'
        //var url = window.location.origin + '/view?' + params
        //window.open(filePath)
        this.downloadFile(filePath)
    }

    viewScale = () => {
        var scale = this.state.scaleList[this.state.scaleIndex] * 100;

        return scale.toFixed(0)
    }

    onClickSignedBadge() {
        this.setState({
            showSigned: !this.state.showSigned
        })
    }

    onClickRotate = () => {
        this.setState({ rotation: (this.state.rotation + 90) % 360 });
    };

    // scrollToTop = () => window.scrollTo({ top: 0 });

    render() {
        let { filePath, readOnly, base64PDF, isSigned, signedBy, signedEmail, signedOn, showSigned } = this.state
        //console.log(filePath)
        if (!filePath) {
            return null;
        }

        return (
            <div
                className="Showcase Showcase--react-pdf"
                onContextMenu={(e) => e.preventDefault()}
            >
                <iframe name="downloadIframe" id="downloadIframe" style={{ display: 'none' }} ></iframe>
                <div className="pdf-paging">
                    {
                        Array
                            .from({ length: this.state.numPages })
                            .map((_, index) => (
                                // Don't change it. It's a lazy loading.
                                <React.Suspense
                                    key={index}
                                    fallback={() => <p>Cannot load pdf</p>}
                                >
                                    <PDFThumnail
                                        file={base64PDF}
                                        onLoadError={this.onDocumentLoadError}
                                        onLoadSuccess={this.onDocumentLoadSuccess}
                                        options={options}
                                        pageNumber={(index + 1)}
                                        currentPageNumber={this.state.pageNumber}
                                        scale={this.state.scaleList[0]}
                                        onClick={(_) => this.onClickPageThumnail(index + 1)}
                                    />
                                </React.Suspense>
                            ))
                    }
                </div>
                <div
                    className="react-component"
                    onContextMenu={(e) => e.preventDefault()}
                >
                    <Document
                        className="selected-document"
                        file={base64PDF}
                        onLoadError={this.onDocumentLoadError}
                        onLoadSuccess={this.onDocumentLoadSuccess as any}
                        // className="pdf-container"
                        options={options}
                    >
                        <Page
                            pageNumber={this.state.pageNumber}
                            scale={this.state.scaleList[this.state.scaleIndex]}
                            rotate={((this.state.defaultPageRotates[this.state.pageNumber - 1] ?? 0) + this.state.rotation) % 360}
                        />
                        <div className="page-controls">
                            <button id="btnPreviousPage" type="button" onClick={this.onClickGoToPreviousPage}>‹</button>
                            <span>{this.state.pageNumber} of {this.state.numPages}</span>
                            <button id="btnNextPage" type="button" onClick={this.onClickGoToNextPage}>›</button>
                            <span>&nbsp;|&nbsp;</span>
                            <button id="btnScaleDown" type="button" onClick={this.onClickScaleDown}>-</button>
                            <span>{this.viewScale()}%</span>
                            <button id="btnScaleUp" type="button" onClick={this.onClickScaleUp}>+</button>
                            {readOnly == false &&
                                <div style={{ display: "inline-block" }}>
                                    <span>&nbsp;|&nbsp;</span>
                                    <button id="btnDownload" type="button" onClick={this.onClickDownload}><FaDownload /></button>
                                </div>
                            }
                            <button id="btnRotate" type="button" onClick={this.onClickRotate}><FiRotateCw /></button>
                        </div>
                        {isSigned &&
                            <div className="show-signed">
                                <button id="btnSigned" type="button" style={{ position: 'relative', opacity: 1, background: 'none', border: 'none', height: '60px', width: '30px' }} onClick={this.onClickSignedBadge}>
                                    <span style={{ position: 'absolute', top: '5px', left: '3px' }}>
                                        <FaCertificate style={{ fontSize: '24px', color: 'white', margin: 0 }} />
                                    </span>
                                    <span style={{ position: 'relative', top: '-10px', left: '0' }}>
                                        <FaCheck style={{ fontSize: '14px', color: 'rgb(80, 200, 120)', margin: 0 }} />
                                    </span>
                                </button>
                                {showSigned &&
                                    <div className="signed-data">
                                        <span className="truncate_text" data-id="name">
                                            <p style={{ fontSize: '14px', color: 'white', margin: 0 }}>
                                                Signed By: {signedBy}
                                            </p>
                                        </span>
                                        {/*<span className="truncate_text" data-id="email">*/}
                                        {/*    <p style={{ fontSize: '11px', color: 'rgb(var(--grey_5))', margin: 0 }}>*/}
                                        {/*        {signedEmail}*/}
                                        {/*    </p>*/}
                                        {/*</span>*/}
                                        <span className="truncate_text" data-id="date">
                                            <p style={{ fontSize: '11px', color: 'white', margin: 0 }}>
                                                Signed On: {signedOn}
                                            </p>
                                        </span>
                                    </div>
                                }
                            </div>
                        }
                    </Document>
                </div>
            </div>
        );
    }
}

export default PDFView;
