import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as FileUpload from '../models/FileUpload';

interface RequestFunctionAction {
    type: 'REQUEST_FILEUPLOAD';
    version: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_FILEUPLOAD';
    model: FileUpload.ResponseFile;
    version: number;
}

interface ClearStateAction {
    type: 'CLEAR'
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ClearStateAction

export const actionCreators = {
    requestFunction: (FileUpload: any, selectedFile: FileUpload.FileUpload, version: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const formData = new FormData();
        formData.append("files", FileUpload[0]);
        formData.append("key", selectedFile.keyIndex);
        formData.append("pid", selectedFile.primaryIndex);
        formData.append("role", selectedFile.role);
        formData.append("meta", selectedFile.metadata
            .map(([key, value], _index) => `${key}:${value}`)
            .join('|')
        );
        formData.append("bucket", selectedFile.bucket);
        formData.append("group_id", selectedFile.group_id);
        formData.append("sub_group_id", selectedFile.sub_group_id);
        formData.append("box_id", selectedFile.box_id);

        if (appState && appState.fileUpload) {

            fetch(`/v1/dms/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
                body: formData
            })
                .then(response => {
                    return response.json() as Promise<FileUpload.ResponseFile>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_FILEUPLOAD', model: data, version: version });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_FILEUPLOAD', version: version });
                });

        }
    },
    clear: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR' })
    }
};

const unloadedState: FileUpload.FileState = { isLoading: false, response: undefined, version: 0 };

export const reducer: Reducer<FileUpload.FileState> = (state: FileUpload.FileState | undefined, incomingAction: Action): FileUpload.FileState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_FILEUPLOAD':
            return {
                isLoading: false,
                version: action.version + 1
            };
        case 'RECEIVE_FILEUPLOAD':
            return {
                isLoading: true,
                response: action.model,
                version: action.version + 2
            };
        case 'CLEAR':
            return { ...unloadedState };
        default: return state;
    }
};