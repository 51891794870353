import * as React from 'react';
import { Button, Modal, ModalHeader, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input, Label, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/FolderManage';
import * as Models from '../../models/Folder';
import * as StoresMetaData from '../../store/MetaData';
import * as ModelsMetaData from '../../models/MetaData';
import * as ModelsMetaDataGroup from '../../models/MetaDataGroup';
import * as StoreMetaDataGroup from '../../store/MetaDataGroup';
import * as StoresRoleAccess from '../../store/RoleAccess';
import * as ModelsRoleAccess from '../../models/RoleAccess';
import Swal from 'sweetalert2';
import FolderImg from '../../Images/Icon/folder.png';
import { FaPlusCircle } from 'react-icons/fa';

//Select Box
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { debug } from 'console';

interface State {
    check: Models.checkFolder;
    pageLoad: boolean;
    modelListRoleAccess: Array<ModelsRoleAccess.RoleAccess>;
    modelRoleAccess: ModelsRoleAccess.RoleAccess;
    valueSelectMetaGroup: any;
    modelListGet: Array<ModelsMetaDataGroup.MetaDataGroup>;
    modelListGetDetail: Array<ModelsMetaDataGroup.MetaDataGroup>;
    version: number;
    nameMetaOrRole: string;
    multiValue: any;
    valueSelect: any;
    modelMetaDataGroup: ModelsMetaDataGroup.MetaDataGroup;
    switchPageMetaOrRole: any;
    isOpenModalMeta: any;
    valueRole: any;
    valueMeataData: any;
    isOpenModal: boolean;
    modelFolder: Models.Folder;
    isSuccess?: boolean;
    folderName: string;
    valueSelectRole: any;
    valueSelectFileExtension: any;
}

class ModalManageFolderComponent extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            isOpenModal: false,
            isOpenModalMeta: false,
            check: {
                checkPdf: false,
                checkPng: false,
                checkJpg: false,
                checkZip: false,
                checkWork: false,
                checlAll: false,
                discheckPdf: false,
                discheckPng: false,
                discheckJpg: false,
                discheckZip: false,
                discheckWork: false,
            },
            switchPageMetaOrRole: "",
            nameMetaOrRole: "",
            multiValue: [],
            valueSelect: [],
            valueSelectMetaGroup: [],
            valueSelectRole: [],
            valueSelectFileExtension: [],
            version: 0,
            modelListGet: [],
            modelListGetDetail: [],
            modelFolder: {
                sid: "",
                company_code: "",
                folder_name: "",
                full_path: "",
                node_code: "",
                node_parent: "",
                created_by: "",
                created_on: "",
                updated_by: "",
                updated_on: "",
                node_level: 0,
                metadata_group_code: "",
                role_access_code: "",
                max_file_size: "",
                file_extension: ""
            },
            modelMetaDataGroup: {
                sid: "DEV",
                metadata_group_code: "metadata_group_code",
                metadata_group_name: "metadata_group_name",
                metadata_code: "metadata_code",
                metadata_name: "metadata_name",
                created_by: "created_by",
                created_on: "created_on",
                updated_by: "updated_by",
                updated_on: "updated_on",
            },
            isSuccess: undefined,
            folderName: "",
            valueMeataData: [],
            valueRole: [],
            modelRoleAccess: {
                sid: "DEV",
                role_access_code: "",
                role_access_name: "",
                created_by: "",
                created_on: "",
                updated_by: "",
                updated_on: "",
                can_view_file: false,
                can_edit_file: false,
                can_edit_folder: false,
                can_edit_box: false,
                can_edit_relation: false,
                can_create_file: false,
                can_create_folder: false,
                can_create_box: false,
                can_create_relation: false,
            },
            modelListRoleAccess: [],
            pageLoad: true
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalMeta = this.toggleModalMeta.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeMataDataCode = this.handleChangeMataDataCode.bind(this);
        this.handleChangeMataDataName = this.handleChangeMataDataName.bind(this);
        this.handleChangeFileExtension = this.handleChangeFileExtension.bind(this);
        this.renameClick = this.renameClick.bind(this);
        this.addClick = this.addClick.bind(this);
        this.editClick = this.editClick.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
        this.actionsClick = this.actionsClick.bind(this)
    }

    private toggleModal() {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }

    private toggleModalMeta(value) {
        this.setState({
            isOpenModalMeta: !this.state.isOpenModalMeta,
        });
        if (value === "Meta") {
            this.props.requestFunction(this.state.version, "GET");
            this.setState({ switchPageMetaOrRole: value, nameMetaOrRole: "Add Mata Data Group", valueSelect: [], multiValue: [] })
        } else if (value === "Role") {
            this.setState({ switchPageMetaOrRole: value, nameMetaOrRole: "Add Role Access" })
        } else if (value === "clear") {
            this.setState({
                valueSelect: [], multiValue: []
            })
        }
    }

    componentDidMount() {
        this.props.requestFunction(this.state.version, "GET");
        this.props.requestFunctionGroup(this.state.version, "GET");
        this.setState({ pageLoad: true });
        this.props.requestFunctionRole(this.state.pageLoad, this.state.version, "GET", { role_access_code: "" });
        this.setState({ pageLoad: false });
    }

    componentDidUpdate() {
        const { response, versionGroup, isLoading } = this.props
        if (this.props.isLoadingGroup === true) {
            this.formatDataGet(versionGroup)
        }

        if (this.props.isLoadingRole) {
            if (response !== undefined) {
                var DataSelectArray = this.state.valueSelectRole
                for (var i = 0; i < response.total_count; i++) {
                    let data = {}
                    data['value'] = response.result_list[i].role_access_code
                    data['label'] = response.result_list[i].role_access_name
                    DataSelectArray.push(data)
                }
            }
        }

        if (this.props.dataModal.isOpenModal) {
            this.props.afterOpenModal();

            let dataModal = this.props.dataModal

            var valueRole: any
            var valueMeataData: any
            var modelFolder: Models.Folder = {
                sid: "",
                company_code: "",
                folder_name: dataModal.folderName,
                node_code: dataModal.nodeCode,
                node_parent: dataModal.nodeParent,
                created_by: "",
                created_on: "",
                updated_by: "",
                updated_on: "",
                node_level: dataModal.nodeLevel ? parseInt(dataModal.nodeLevel) : 0,
                full_path: "",
                metadata_group_code: dataModal.metadata_group_code,
                role_access_code: dataModal.metadata_group_code,
                max_file_size: dataModal.metadata_group_code,
                file_extension: dataModal.metadata_group_code,
            };

            if (dataModal.mode === "add") {
                modelFolder.folder_name = "";
                modelFolder.node_parent = dataModal.nodeCode;
                modelFolder.node_level = dataModal.nodeLevel ? (parseInt(dataModal.nodeLevel) + 1) : 0;
                modelFolder.node_code = "";
                modelFolder.metadata_group_code = "-";
                modelFolder.role_access_code = "*";
                modelFolder.max_file_size = "10485760"; //10MB
                modelFolder.file_extension = "*";

                valueMeataData = ["-"];
                valueRole = ["*"];
            } else if (dataModal.mode === "edit") {
                modelFolder.folder_name = this.props.detailFolder.folder_name;
                modelFolder.node_parent = dataModal.nodeCode;
                modelFolder.node_level = dataModal.nodeLevel ? (parseInt(dataModal.nodeLevel) + 1) : 0;
                modelFolder.node_code = this.props.detailFolder.node_code;
                modelFolder.metadata_group_code = this.props.detailFolder.metadata_group_code
                modelFolder.role_access_code = this.props.detailFolder.role_access_code
                modelFolder.max_file_size = this.props.detailFolder.max_file_size; //10MB
                modelFolder.file_extension = this.props.detailFolder.file_extension;

                var DataSelectArray: any = []
                var DataSelectMeta: any = []
                var rolData = this.props.detailFolder.role_access_code.split("|")

                let data = {}
                data['value'] = this.props.detailFolder.metadata_group_code
                data['label'] = this.props.detailFolder.metadata_group_code
                DataSelectMeta.push(data)

                for (var i = 0; i < rolData.length; i++) {
                    let data = {}
                    data['value'] = rolData[i]
                    data['label'] = rolData[i]
                    DataSelectArray.push(data)
                }

                valueMeataData = DataSelectMeta
                valueRole = DataSelectArray

                var valuetype = this.props.detailFolder.file_extension.split("/")

                var checkvalue: any = []
                for (let v = 0; v < valuetype.length; v++) {
                    console.log(valuetype[v])
                    if (valuetype[v] === ".pdf") {
                        checkvalue.checkPdf = true
                    } else if (valuetype[v] === ".png") {
                        checkvalue.checkPng = true
                    } else if (valuetype[v] === ".jpeg|.jpg") {
                        checkvalue.checkJpg = true
                    } else if (valuetype[v] === ".zip") {
                        checkvalue.checkZip = true
                    } else if (valuetype[v] === ".doc|.docx|.xls|.xlsx|.ppt|.pptx") {
                        checkvalue.checkWork = true
                    } else if (valuetype[v] === "*") {
                        checkvalue.checlAll = true
                        checkvalue.discheckPdf = true
                        checkvalue.discheckPng = true
                        checkvalue.discheckJpg = true
                        checkvalue.discheckZip = true
                        checkvalue.discheckWork = true
                        break
                    }
                }
                this.setState({
                    check: checkvalue
                })
            }

            this.setState({
                isOpenModal: true,
                folderName: dataModal.folderName,
                modelFolder: modelFolder,
                valueMeataData: valueMeataData,
                valueRole: valueRole
            });
        }

        if (this.props.isSuccess === false) {

            this.afterSuccess();

        }
        else if (this.props.isSuccess === true) {
            this.afterError();

        }
    }

    public setModelFolderState(key: keyof Models.Folder, value: string) {
        const modelFolder = { ...this.state.modelFolder, [key]: value } as Models.Folder;

        this.setState({ modelFolder });
    }

    private handleChangeName(e: any) {
        var folder: Models.Folder = this.state.modelFolder;
        folder.folder_name = e.target.value;

        this.setState({ modelFolder: folder });
    }

    private handleChangeMaxFileSize(e: any) {
        var folder: Models.Folder = this.state.modelFolder;
        folder.max_file_size = e.target.value;

        this.setState({ modelFolder: folder });
    }

    private handleChangeFileExtension(e: any) {
        var name = "cbFileExtension"
        var Uniqe = "cbFileExtensionUniqe"
        var checkdisable: boolean = false
        Array.from(document.querySelectorAll("input[name=" + Uniqe + "]"))
            .forEach((checkbox) => {
                var cb = checkbox as HTMLInputElement;
                if (cb.checked === true) {
                    checkdisable = true
                }
            });

        Array.from(document.querySelectorAll("input[name=" + name + "]"))
            .forEach((checkbox) => {
                var cb = checkbox as HTMLInputElement;
                if (checkdisable === true) {
                    cb.checked = false
                    cb.disabled = true
                } else if (checkdisable === false) {
                    cb.disabled = false
                }
            });
    }

    private renameClick(e: any) {
        this.props.requestFunctionFolder("RENAME", this.state.modelFolder);
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    private addClick(e: any) {
        var valueRole: string = ""
        var valueCheck: string = ""
        for (let i = 0; i < this.state.valueRole.length; i++) {
            if (i === 0) {
                valueRole += this.state.valueRole[i].value
            } else {
                valueRole += "|" + this.state.valueRole[i].value
            }
        }
        var Uniqe = "cbFileExtensionUniqe"
        var checkdisable: boolean = false
        Array.from(document.querySelectorAll("input[name=" + Uniqe + "]"))
            .forEach((checkbox) => {
                var cb = checkbox as HTMLInputElement;
                if (cb.checked === true) {
                    checkdisable = true
                    if (cb.dataset.fileextension) {
                        valueCheck = cb.dataset.fileextension
                    }
                }
            });
        if (checkdisable === false) {
            var name = "cbFileExtension"
            Array.from(document.querySelectorAll("input[name=" + name + "]"))
                .forEach((checkbox, key) => {
                    var cb = checkbox as HTMLInputElement;
                    if (cb.checked) {
                        if (key === 0) {
                            valueCheck += cb.dataset.fileextension
                        } else {
                            valueCheck += "/" + cb.dataset.fileextension
                        }
                    }
                });
        }

        var data = this.state.modelFolder
        data.metadata_group_code = this.state.valueMeataData.value
        data.role_access_code = valueRole
        data.file_extension = valueCheck

        this.setState({
            modelFolder: data
        })

        this.props.requestFunctionFolder("ADD", this.state.modelFolder);
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    private editClick(e: any) {
        var valueRole: string = ""
        var valueCheck: string = ""
        for (let i = 0; i < this.state.valueRole.length; i++) {
            if (i === 0) {
                valueRole += this.state.valueRole[i].value
            } else {
                valueRole += "|" + this.state.valueRole[i].value
            }
        }
        var Uniqe = "cbFileExtensionUniqe"
        var checkdisable: boolean = false
        Array.from(document.querySelectorAll("input[name=" + Uniqe + "]"))
            .forEach((checkbox) => {
                var cb = checkbox as HTMLInputElement;
                if (cb.checked === true) {
                    checkdisable = true
                    if (cb.dataset.fileextension) {
                        valueCheck = cb.dataset.fileextension
                    }
                }
            });
        if (checkdisable === false) {
            var name = "cbFileExtension"
            Array.from(document.querySelectorAll("input[name=" + name + "]"))
                .forEach((checkbox, key) => {
                    var cb = checkbox as HTMLInputElement;
                    if (cb.checked) {
                        if (key === 0) {
                            valueCheck += cb.dataset.fileextension
                        } else {
                            valueCheck += "/" + cb.dataset.fileextension
                        }
                    }
                });
        }
        var data = this.state.modelFolder
        if (this.state.valueMeataData.value !== undefined) {
            data.metadata_group_code = this.state.valueMeataData.value
        }
        data.role_access_code = valueRole
        data.file_extension = valueCheck

        this.setState({
            modelFolder: data
        })

        this.props.requestFunctionFolder("EDIT", this.state.modelFolder);
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    private deleteClick(e: any) {
        this.props.requestFunctionFolder("DELETE", this.state.modelFolder);
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    handleChange = (newValue: any, actionMeta: any, check: string) => {
        if (check === "Meta") {
            this.setState({ valueMeataData: newValue })
        } else if (check === "Role") {
            this.setState({ valueRole: newValue })
        }
    };

    handleInputChange = (inputValue: any, actionMeta: any, check: string) => {

    };

    setTextHeaderModal = () => {
        var txtHead = "";
        if (this.props.dataModal.mode === "rename") {
            txtHead = "Rename folder "
        } else if (this.props.dataModal.mode === "add") {
            txtHead = "Add folder in "
        } else if (this.props.dataModal.mode === "edit") {
            txtHead = "Edit folder in "
        } else if (this.props.dataModal.mode === "delete") {
            txtHead = "Delete folder "
        }
        return txtHead;
    }

    setBodyModal = () => {
        const { valueMeataData } = this.state
        if (this.props.dataModal.mode === "rename") {
            return <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ width: "160px" }}>Folder Name</InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Folder Name" type="text" value={this.state.modelFolder.folder_name} onChange={this.handleChangeName} />
            </InputGroup>;
        } else if (this.props.dataModal.mode === "add") {
            return <InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Node Code</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: "800px" }}>
                        <Input placeholder="Node Code" type="text" value={this.state.modelFolder.node_code} onChange={event => this.setModelFolderState('node_code', event.target.value)} />
                    </div>
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Folder Name</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: "800px" }}>
                        <Input placeholder="Folder Name" type="text" value={this.state.modelFolder.folder_name} onChange={this.handleChangeName} />
                    </div>
                </InputGroup>
                {/* <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Metadata Group</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: "800px" }}>
                        <CreatableSelect
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isClearable
                            onChange={(value, action) => this.handleChange(value, action, "Meta")}
                            onInputChange={(value, action) => this.handleInputChange(value, action, "Meta")}
                            options={this.state.valueSelectMetaGroup}
                        />
                    </div>
                    &nbsp;&nbsp;
                    <Button outline color="success" onClick={() => this.toggleModalMeta("Meta")} style={{ width: "90px" }}><FaPlusCircle style={{ marginBottom: "4px" }} />&nbsp;&nbsp;Add</Button>
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Role Access</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: "800px" }}>
                        <CreatableSelect
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isMulti
                            isClearable
                            onChange={(value, action) => this.handleChange(value, action, "Role")}
                            onInputChange={(value, action) => this.handleInputChange(value, action, "Role")}
                            options={this.state.valueSelectRole}
                        />
                    </div>&nbsp;&nbsp;
                    <Button outline color="success" onClick={() => this.toggleModalMeta("Role")} style={{ width: "90px" }}><FaPlusCircle style={{ marginBottom: "4px" }} />&nbsp;&nbsp;Add</Button>
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Maximum File Size</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: "800px" }}>
                        <Input placeholder="Maximum File Size" type="text" value={this.state.modelFolder.max_file_size} onChange={this.handleChangeMaxFileSize} />
                    </div>
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>File Extension</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ marginLeft: "50px", width: "750px", alignSelf: "center" }}>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    name="cbFileExtension"
                                    data-fileextension=".pdf"
                                />{' '}
                                PDF
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    name="cbFileExtension"
                                    data-fileextension=".png"
                                />{' '}
                                Image - PNG
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    name="cbFileExtension"
                                    data-fileextension=".jpeg|.jpg"
                                />{' '}
                                Image - JPEG
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    name="cbFileExtension"
                                    data-fileextension=".zip"
                                />{' '}
                                Compress - ZIP
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    name="cbFileExtension"
                                    data-fileextension=".doc|.docx|.xls|.xlsx|.ppt|.pptx"
                                />{' '}
                                Microsoft Office - Word, Excel, Power Point
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox" id="cbFileExtensionUniqe"
                                    name="cbFileExtensionUniqe"
                                    data-fileextension="*"
                                    onChange={this.handleChangeFileExtension}
                                />{' '}
                                All
                            </Label>
                        </InputGroup>
                    </div>
                </InputGroup> */}
            </InputGroup>
        } else if (this.props.dataModal.mode === "edit") {
            return <InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Folder Name</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: "800px" }}>
                        <Input placeholder="Folder Name" type="text" defaultValue={this.state.modelFolder.folder_name} onChange={this.handleChangeName} />
                    </div>
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Metadata Group</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: "800px" }}>
                        <CreatableSelect
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isClearable
                            defaultValue={this.state.valueMeataData}
                            onChange={(value, action) => this.handleChange(value, action, "Meta")}
                            onInputChange={(value, action) => this.handleInputChange(value, action, "Meta")}
                            options={this.state.valueSelectMetaGroup}
                        />
                    </div>
                    &nbsp;&nbsp;
                    <Button outline color="success" onClick={() => this.toggleModalMeta("Meta")} style={{ width: "90px" }}><FaPlusCircle style={{ marginBottom: "4px" }} />&nbsp;&nbsp;Add</Button>
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Role Access</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: "800px" }}>
                        <CreatableSelect
                            isMulti
                            isClearable
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            //defaultValue={this.state.valueSelectRole.filter(obj => this.state.valueRole.includes(obj.value))}
                            defaultValue={this.state.valueRole}
                            onChange={(value, action) => this.handleChange(value, action, "Role")}
                            onInputChange={(value, action) => this.handleInputChange(value, action, "Role")}
                            options={this.state.valueSelectRole}
                        />
                    </div>&nbsp;&nbsp;
                    <Button outline color="success" onClick={() => this.toggleModalMeta("Role")} style={{ width: "90px" }}><FaPlusCircle style={{ marginBottom: "4px" }} />&nbsp;&nbsp;Add</Button>
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Maximum File Size</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: "800px" }}>
                        <Input placeholder="Maximum File Size" type="text" value={this.state.modelFolder.max_file_size} onChange={this.handleChangeMaxFileSize} />
                    </div>
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>File Extension</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ marginLeft: "50px", width: "750px", alignSelf: "center" }}>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    defaultChecked={this.state.check.checkPdf}
                                    disabled={this.state.check.discheckPdf}
                                    name="cbFileExtension"
                                    data-fileextension=".pdf"
                                />{' '}
                                PDF
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    defaultChecked={this.state.check.checkPng}
                                    disabled={this.state.check.discheckPng}
                                    name="cbFileExtension"
                                    data-fileextension=".png"
                                />{' '}
                                Image - PNG
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    defaultChecked={this.state.check.checkJpg}
                                    disabled={this.state.check.discheckJpg}
                                    name="cbFileExtension"
                                    data-fileextension=".jpeg|.jpg"
                                />{' '}
                                Image - JPEG
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    defaultChecked={this.state.check.checkZip}
                                    disabled={this.state.check.discheckZip}
                                    name="cbFileExtension"
                                    data-fileextension=".zip"
                                />{' '}
                                Compress - ZIP
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    defaultChecked={this.state.check.checkWork}
                                    disabled={this.state.check.discheckWork}
                                    name="cbFileExtension"
                                    data-fileextension=".doc|.docx|.xls|.xlsx|.ppt|.pptx"
                                />{' '}
                                Microsoft Office - Word, Excel, Power Point
                            </Label>
                        </InputGroup>
                        <InputGroup check="true">
                            <Label check>
                                <Input type="checkbox"
                                    defaultChecked={this.state.check.checlAll}
                                    name="cbFileExtensionUniqe"
                                    data-fileextension="*"
                                    onChange={this.handleChangeFileExtension}
                                />{' '}
                                All
                            </Label>
                        </InputGroup>
                    </div>
                </InputGroup>
            </InputGroup>
        } else if (this.props.dataModal.mode === "delete") {
            return <Label>Are you sure to delete folder <span style={{ color: "red" }}> {this.state.modelFolder.folder_name}. </span></Label>;
        }
    }

    private handleChangeMataDataCode(e: any, check: any) {
        if (check === "Meta") {
            var data = this.state.modelMetaDataGroup;
            data.metadata_group_code = e.target.value;

            this.setState({
                modelMetaDataGroup: data
            });
        } else if (check === "Role") {
            var dataRole = this.state.modelRoleAccess;
            dataRole.role_access_code = e.target.value;

            this.setState({
                modelRoleAccess: dataRole
            });
        }
    }

    private handleChangeMataDataName(e: any, check) {
        if (check === "Meta") {
            var data = this.state.modelMetaDataGroup;
            data.metadata_group_name = e.target.value;

            this.setState({
                modelMetaDataGroup: data
            });
        } else if (check === "Role") {
            var dataRole = this.state.modelRoleAccess;
            dataRole.role_access_name = e.target.value;

            this.setState({
                modelRoleAccess: dataRole
            });
        }
    }

    private handleChangeFile(e: any, check: string) {
        var data = this.state.modelRoleAccess

        if (check === "viewfile") {
            data.can_view_file = e.target.checked
        } else if (check === "editfile") {
            data.can_edit_file = e.target.checked
        } else if (check === "editfolder") {
            data.can_edit_folder = e.target.checked
        } else if (check === "editbox") {
            data.can_edit_box = e.target.checked
        } else if (check === "editrelation") {
            data.can_edit_relation = e.target.checked
        } else if (check === "createfile") {
            data.can_create_file = e.target.checked
        } else if (check === "createfolder") {
            data.can_create_folder = e.target.checked
        } else if (check === "createbox") {
            data.can_create_box = e.target.checked
        } else if (check === "createrelation") {
            data.can_create_relation = e.target.checked
        }

        this.setState({
            modelRoleAccess: data
        });
    }

    handleValueSelectAdd = (e, { action }) => {
        this.setState({
            multiValue: (Array.isArray(e) ? e.map(x => x.value) : [])
        });
    }

    setBodyModalMeta = () => {
        const { switchPageMetaOrRole } = this.state
        if (switchPageMetaOrRole === "Meta") {
            return <div style={{ paddingBottom: 0 }}>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-group-font">Metadata Group Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" onChange={(e) => this.handleChangeMataDataCode(e, "Meta")} />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-group-font">Metadata Group Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" onChange={(e) => this.handleChangeMataDataName(e, "Meta")} />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend" >
                        <InputGroupText className="metadata-group-font" style={{ width: 190 }}>Select Metadata</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: '80%' }}>
                        <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            className="dropdown metadata-group-font"
                            placeholder="Select Metadata"
                            value={this.state.valueSelect.filter(obj => this.state.multiValue.includes(obj.value))}
                            options={this.state.valueSelect}
                            onChange={this.handleValueSelectAdd}
                            isMulti
                            isClearable
                        />
                    </div>
                </InputGroup>
            </div>;
        } else if (switchPageMetaOrRole === "Role") {
            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="role-font">Role Access Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Role Access Code" type="text" onChange={(e) => this.handleChangeMataDataCode(e, "Role")} />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="role-font">Role Access Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Role Access Name" type="text" onChange={(e) => this.handleChangeMataDataName(e, "Role")} />
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Permission</InputGroupText>
                    </InputGroupAddon>

                    <div className="row" style={{ width: "80%" }}>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFile(e, "viewfile")}
                                    />{' '}
                                    View File
                                </Label>
                            </InputGroup>
                        </div>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFile(e, "editfile")}
                                    />{' '}
                                    Edit File
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFile(e, "editfolder")}
                                    />{' '}
                                    Edit Folder
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFile(e, "editbox")}
                                    />{' '}
                                    Edit Box
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFile(e, "editrelation")}
                                    />{' '}
                                    Edit Relation
                                </Label>
                            </InputGroup>
                        </div>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFile(e, "createfile")}
                                    />{' '}
                                    Create File
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFile(e, "createfolder")}
                                    />{' '}
                                    Create Folder
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFile(e, "createbox")}
                                    />{' '}
                                    Create Box
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFile(e, "createrelation")}
                                    />{' '}
                                    Create Relation
                                </Label>
                            </InputGroup>
                        </div>
                    </div>
                </InputGroup>
            </div>;
        }
    }

    setButtonSaveModal = () => {
        if (this.props.dataModal.mode === "rename") {
            return <Button color="info" onClick={this.renameClick}>Rename</Button>;
        } else if (this.props.dataModal.mode === "add") {
            return <Button color="primary" onClick={this.addClick}>Add</Button>;
        } else if (this.props.dataModal.mode === "edit") {
            return <Button color="warning" onClick={this.editClick}>Edit</Button>;
        } else if (this.props.dataModal.mode === "delete") {
            return <Button color="danger" onClick={this.deleteClick}>Delete</Button>;
        }
    }

    private actionsClick(type) {
        if (type === "PostMeta") {
            if (this.state.multiValue !== "") {
                var countValue = []
                countValue = this.state.multiValue
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                this.props.requestFunctionGroup(this.state.version, "POST", this.state.modelMetaDataGroup, countValue);
                this.toggleModalMeta("")

            } else {
                this.props.requestFunctionGroup(this.state.version, "POST", this.state.modelMetaDataGroup);
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                this.toggleModalMeta("")
            }
        }
        else if (type === "PostRole") {
            this.props.requestFunctionRole(this.state.pageLoad, this.state.version, "POST", this.state.modelRoleAccess);
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            this.toggleModalMeta("")
        }
    }

    setButtonModalMeta = () => {

        if (this.state.switchPageMetaOrRole === "Meta") {
            return <Button color="primary" className="metadata-group-font" onClick={() => this.actionsClick('PostMeta')}>Add</Button>;
        } else if (this.state.switchPageMetaOrRole === "Role") {
            return <Button color="primary" className="role-font" onClick={() => this.actionsClick('PostRole')}>Add</Button>;
        }
    }

    //afterCallAPI = () => {
    //    //console.log("after Call API -");
    //    //if (this.props.version - this.state.version === 2) {
    //    //    //get
    //    //    this.afterGetDataSuccess();
    //    //} else if (this.props.version - this.state.version === 3) {
    //    //    //post
    //    //    this.afterAddFolderSuccess();
    //    //} else if (this.props.version - this.state.version === 4) {
    //    //    //put
    //    //    this.afterRenameFolderSuccess();
    //    //}

    //    //this.setState({ version: this.props.version });
    //}

    //afterGetDataSuccess = () => {
    //    console.log("after Get Data Success -");
    //    var result = this.props.response.result_list[0] as Models.Folder;
    //    var folderName = result.folder_name;
    //    if (this.props.dataModal.mode === "add") {
    //        result.folder_name = "";
    //        result.node_parent = result.node_code;
    //        result.node_level = result.node_level + 1;
    //        result.node_code = "";
    //    }
    //    this.setState({
    //        modelFolder: result,
    //        isGetSuccess: true,
    //        folderName: folderName,
    //        version: this.props.version
    //    });

    //    setTimeout(() => {
    //        console.log(this.state.modelFolder);
    //        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
    //        this.toggleModal();
    //    }, 500);
    //}

    afterSuccess = () => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

        Swal.fire({
            title: 'Success',
            icon: 'success'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.requestFunctionFolder("CLEAR", this.state.modelFolder);
                this.toggleModal();
                this.props.afterModalSaveSuccess();
            }
        })
    }

    afterError = () => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

        Swal.fire({
            title: 'Error',
            icon: 'error'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.requestFunctionFolder("CLEAR", this.state.modelFolder);
                this.toggleModal();
                this.props.afterModalSaveSuccess();
            }
        })
    }

    getUnique(arr, index) {
        if (arr === undefined
            || arr === null) {
            return null;
        }
        
        const unique = arr
            .map(e => e[index])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }

    formatDataGet = (versionGroup) => {
        var valueGetSelect = this.props.responseGroup.result_list as Array<ModelsMetaDataGroup.MetaDataGroup>
        var valueGet = this.props.responseGroup.result_list as Array<ModelsMetaDataGroup.MetaDataGroup>
        var valueSelectMetaGroup: any = []
        if (this.props.responseGroup !== undefined) {
            var DataSelectArray = this.state.valueSelectMetaGroup
            for (var i = 0; i < this.props.responseGroup.total_count; i++) {
                let data = {}
                data['value'] = valueGet[i].metadata_group_code
                data['label'] = valueGet[i].metadata_group_name
                DataSelectArray.push(data)
            }
            valueSelectMetaGroup = this.getUnique(DataSelectArray, 'value')
            this.setState({ valueSelectMetaGroup: valueSelectMetaGroup })
        }

        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        valueGet = this.getUnique(valueGet, 'metadata_group_code')

        this.props.requestFunctionGroup(this.state.version, 'CLEAR');
        if (versionGroup === 10) {
            this.setState({ modelListGet: valueGet, modelListGetDetail: valueGetSelect });

        } else {
            this.setState({ modelListGet: valueGet, modelListGetDetail: valueGetSelect });
        }
    }

    render() {
        const { isLoading, response, versionGroup, isLoadingGroup, isLoadingRole, isSuccess } = this.props
        if (isLoading) {
            if (response !== undefined) {
                var DataSelectArray = this.state.valueSelect
                for (var i = 0; i < response.total_count; i++) {
                    let data = {}
                    data['value'] = response.result_list[i].metadata_code
                    data['label'] = response.result_list[i].metadata_name
                    DataSelectArray.push(data)
                }
            }
        }
        if (isLoadingRole) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }

        return (
            <div id="div-modal-folder-manage">

                <Modal isOpen={this.state.isOpenModal} className="dms-modal">
                    <ModalHeader toggle={this.toggleModal}>
                        {this.setTextHeaderModal()}
                        <span style={{ color: "#117a8b" }}>
                            {this.props.valueCodeAndName !== undefined ? this.props.valueCodeAndName.fullpath : null}.
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-3" style={{ textAlign: "center" }}>
                                <img src={FolderImg} alt="file" style={{ width: "50%" }} />
                            </div>
                            <div className="col-9">
                                {this.setBodyModal()}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.setButtonSaveModal()}
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.isOpenModalMeta} className="dms-modal">
                    <ModalHeader className="metadata-group-font" toggle={this.toggleModalMeta}>{this.state.nameMetaOrRole}</ModalHeader>
                    <ModalBody>

                        {this.setBodyModalMeta()}

                    </ModalBody>
                    <ModalFooter>
                        {this.setButtonModalMeta()}
                        <Button color="secondary" className="metadata-group-font" onClick={() => this.toggleModalMeta("clear")}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) =>
    ({ ...state.folder, ...state.metaData, ...state.metaDataGroup, ...state.roleAccess }),
    ({ ...Stores.actionCreators, ...StoresMetaData.actionCreators, ...StoreMetaDataGroup.actionCreators, ...StoresRoleAccess.actionCreators }))(ModalManageFolderComponent);
