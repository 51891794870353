import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavMenuOrigin from './NavMenu';
import { AdminContext } from '../context/adminProvider';

const NavMenu = NavMenuOrigin as any;

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <AdminContext.Consumer>
            {admin => (
                <NavMenu admin={admin as any} />
            )}
        </AdminContext.Consumer>
        <Container fluid={true}>
            <Row>
                <Col md="12" style={{ marginLeft: 0 }}>
                    <main role="main" style={{ minHeight: "90vh" }}>
                        {props.children}
                    </main>
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);