import { Button, Spinner } from 'reactstrap';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FolderIcon from '@material-ui/icons/Folder';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import * as React from 'react';
//import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import BoxIDImg from '../../../Images/Icon/boxid.png';
import FolderImg from '../../../Images/Icon/folder.png';
//import DeleteImg from '../../../Images/Icon/remove.png';
//import AddImg from '../../../Images/Icon/add.png';
//import EditImg from '../../../Images/Icon/pencil.png';
import { ApplicationState } from '../../../store';
import * as Models from '../../../models/Folder';
import * as Stores from '../../../store/Folder';
// import * as StoresFolder from '../../store/FolderManage';
import { Menu, Item, Separator, Submenu, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
//import { checkExp, getRedirectAppPoolUrl, parseJwt } from '../../../modules/common';

//import ModalManageFolderComponent from '../ModalManageFolderComponent';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { UserContext, IUserContext } from '../../../context/UserProvider';
import { Col, Row } from 'react-bootstrap';

import { AuthorizeStateStore, DisplayAuthorize, DefaultRoleAuthorize } from '../../../models/Authorize';
import { UserAuthMappingFolderTreeCheckBox } from '../../../models/FolderAuthorizeUserMapping';

// tree folder
declare module 'csstype' {
    interface Properties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    labelTextcount: JSX.Element;
    iconImg: string; // Required. Don't give "?" to this property --- NGAME
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:focus > $content, &$selected > $content': {
                backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
                color: 'var(--tree-view-color)',
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent',
            },
        },
        content: {
            color: theme.palette.text.secondary,
            paddingRight: theme.spacing(1),
            fontWeight: 'bold',
            '$expanded > &': {
                fontWeight: 'normal',
            },
            fontFamily: 'sans-serif'
        },
        group: {
            marginLeft: 0,
            borderLeft: "1px dashed #bebebe"
        },
        expanded: {
        },
        selected: {
        },
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
            fontFamily: 'sans-serif'
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0),
        },
        labelIcon: {
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
            fontFamily: 'sans-serif'
        },
    }),
);


function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { nodeId, labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, labelTextcount, ...other } = props;

    //var fileCount = ""
    //if (labelTextcount !== '0') {
    //    fileCount = labelTextcount
    //}

    return (
        <TreeItem
            //onLabelClick={event => {
            //    // event.stopPropagation();
            //    event.preventDefault();
            //}}
            //icon={
            //    <img src={props.iconImg} alt="file" style={{ width: "14px", marginRight: "3px" }} />
            //}
            nodeId={nodeId}
            label={
                <div className={classes.labelRoot}>
                    <img src={props.iconImg} alt="file" style={{ width: "14px", marginRight: "3px" }} />
                    <Typography variant="body2" className={classes.labelText} style={{ fontSize: "12px" }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelTextcount}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

// right click
const MENU_ID = "menu-id";

const { show } = useContextMenu({
    id: MENU_ID
});

// store
// type Store = Models.FolderState & typeof Stores.actionCreators;
// type StoreGetEdit = Models.FolderState & typeof StoresFolder.actionCreators;

interface State {
    detailFolder: any,
    pathAll: string,
    levelPath: any,
    pageLoad: boolean,
    version: number,
    listFolder: Array<Models.FolderMap>,
    isLoadSuccess: boolean,
    dataModal: Models.DataModal,
    expandNodes: string[]
}

class FoldersComponentForUserFolderAuthorization extends React.PureComponent<any, State> {
    static contextType: React.Context<any> | undefined = UserContext;

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            isLoadSuccess: false,
            version: 0,
            listFolder: [],
            dataModal: {
                isOpenModal: false,
                mode: "",
                nodeCode: "",
                folderName: "",
                nodeParent: "",
                nodeLevel: 0,
                metadata_group_code: "",
                role_access_code: "",
                max_file_size: "",
                file_extension: "",
            },
            levelPath: [],
            pathAll: "",
            detailFolder: [],
            expandNodes: []
        }

        this.checkNode = this.checkNode.bind(this);
        this.onClickMoreFolder = this.onClickMoreFolder.bind(this);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.afterModalSaveSuccess = this.afterModalSaveSuccess.bind(this);
        this.checkUserFolderAuth = this.checkUserFolderAuth.bind(this);
    }

    //public componentWillRecieveProps(newProps) {
    //    console.log("[Debug] componentWillRecieveProps() this props", this.props.selectedCompany)
    //    console.log("[Debug] componentWillRecieveProps() new props", newProps)
    //}

    //public shouldComponentUpdate(newProps, newState) {
    //    console.log("[Debug] shouldComponentUpdate() newState", newState.shortNameCompanySelected)
    //    console.log("[Debug] shouldComponentUpdate() newProps", newProps.selectedCompany)

    //    if (newProps.selectedCompany !== this.state.shortNameCompanySelected) {
    //        this.setState({ shortNameCompanySelected: newProps.selectedCompany });
    //    }

    //    return true;
    //}

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate(prevProps) {
        //console.log(this.props.CompanyShortName) // code below is need to be maintenance (performance issues)
        //debugger
        //console.log("[Debug] prevProps", prevProps.selectedCompany)
        //console.log("[Debug] this Props", this.props.selectedCompany)

        if (this.props.response != undefined) {
            if (/*this.props.version > this.state.version &&*/ this.props.CompanyShortName !== undefined && this.props.CompanyShortName.length !== 0) {
                if ((this.props.version - this.state.version) === 2 || this.props.selectedCompany !== prevProps.selectedCompany) {
                    // console.log('ngame original response', this.props.response) // <-- original
                    var List_response: any = []
                    List_response = JSON.parse(JSON.stringify(this.props.response))

                    var systemPosi: number = List_response.findIndex(obj => obj.folder_name === "System")
                    var system_list: any = JSON.parse(JSON.stringify(List_response.filter(item => item.folder_name === 'System' && item.node_code === 'SYS_')))
                    //var system_list = List_response[systemPosi]
                    var archive_zone = system_list[0].list_node.filter(item => item.folder_name === 'Archive Zone')
                    var FocusOneERP = system_list[0].list_node.filter(item => item.folder_name === 'Focus One ERP')

                    var archive_ans: any = []
                    if (this.props.selectedCompany !== null && this.props.selectedCompany !== "") {
                        var archive_ans_: any = archive_zone[0].list_node.filter(item => item.folder_name === this.props.selectedCompany)
                        if (archive_ans_.length !== 0) {
                            archive_ans.push(archive_ans_[0])
                        }
                    } else {
                        for (let i = 0; i < this.props.CompanyShortName.length; i++) {
                            var archive_ans_: any = archive_zone[0].list_node.filter(item => item.folder_name === this.props.CompanyShortName[i])
                            if (archive_ans_.length !== 0) {
                                archive_ans.push(archive_ans_[0])
                            }
                        }
                    }

                    var FocusOneERP_ans: any = []
                    if (this.props.selectedCompany !== null && this.props.selectedCompany !== "") {
                        var focus_ans_: any = FocusOneERP[0].list_node.filter(item => item.folder_name === this.props.selectedCompany)
                        if (focus_ans_.length !== 0) {
                            FocusOneERP_ans.push(focus_ans_[0])
                        }
                    } else {
                        for (let i = 0; i < this.props.CompanyShortName.length; i++) {
                            var focus_ans_: any = FocusOneERP[0].list_node.filter(item => item.folder_name === this.props.CompanyShortName[i])
                            if (focus_ans_.length !== 0) {
                                FocusOneERP_ans.push(focus_ans_[0])
                            }
                        }
                    }
                    archive_zone[0].list_node = []
                    archive_zone[0].list_node = archive_ans

                    FocusOneERP[0].list_node = []
                    FocusOneERP[0].list_node = FocusOneERP_ans

                    //List_response[systemPosi].list_node.splice(0, List_response[systemPosi].list_node.length)
                    List_response[systemPosi] = JSON.parse(JSON.stringify(system_list[0]))

                    // console.log('ngame wtf response', List_response)
                    this.setState({ listFolder: List_response, version: this.props.version });
                    setTimeout(() => {
                        if (this.state.listFolder.length > 0) {
                            this.setState({ isLoadSuccess: true });
                        }
                    }, 500);
                }
            } else if (/*this.props.version > this.state.version &&*/ this.props.CompanyShortName === undefined) {
                var List_response: any = []
                List_response = JSON.parse(JSON.stringify(this.props.response))
                this.setState({ version: this.props.version, listFolder: [] });
                setTimeout(() => {
                    //if (this.state.listFolder.length > 0) {
                    this.setState({ isLoadSuccess: true });
                    //}
                }, 500);
            }
        }

        //if (this.props.reloadFolder === true) {
        //    this.ensureDataFetchedFix()
        //    this.props.resetReloadFolder("reset")
        //}
    }

    private ensureDataFetched() {
        this.props.onSelectFolder("", "", "");
        this.setState({ pageLoad: true, isLoadSuccess: false });
        this.props.requestFunction(this.state.pageLoad, "DEV", "1000", this.state.version, "GET");
        this.setState({ pageLoad: false });
    }

    private ensureDataFetchedFix() {
        this.props.onSelectFolder(this.props.valueCodeAndName.fullpath, this.props.valueCodeAndName.code, this.props.valueCodeAndName.name);
        this.setState({ pageLoad: true, isLoadSuccess: false });
        this.props.requestFunction(true, "DEV", "1000", this.state.version, "GET");
        this.setState({ pageLoad: false });
    }

    private checkNode(item: Array<Models.FolderMap>, index: number) {

        if ((item as Array<Models.FolderMap>).length > 0) {
            return this.renderNode(item);
        }

        return "";
    }

    private onContextMenuClick(e: any) {
        e.stopPropagation();
        var data = {
            isOpenModal: false,
            mode: "",
            nodeCode: e.currentTarget.dataset.nodecode,
            folderName: e.currentTarget.dataset.nodename,
            nodeParent: e.currentTarget.dataset.nodeparent,
            nodeLevel: e.currentTarget.dataset.nodelevel,
            metadata_group_code: e.currentTarget.dataset.metadata,
            role_access_code: "",
            max_file_size: "",
            file_extension: "",
        };
        // console.log('ngame click', data)
        this.setState({ dataModal: data });

        show(e);
    }

    filter(data, key, datause) {
        if (datause !== "") {
            this.setState({ detailFolder: datause })
        } else {
            for (let i = 0; i < data.length; i++) {
                this.filter(data[i].list_node, key, "")
                if (data[i].node_code === key) {
                    this.filter("", "", data[i]);
                    break
                }
            }
        }
    }

    private onClickMoreFolder(e: any) {

        if (e.event.currentTarget.dataset.mode === "edit") {
            var folderDetail: any = this.filter(this.state.listFolder, this.state.dataModal.nodeCode, "")
        }

        var data = {
            isOpenModal: true,
            mode: e.event.currentTarget.dataset.mode,
            nodeCode: this.state.dataModal.nodeCode,
            folderName: this.state.dataModal.folderName,
            nodeParent: this.state.dataModal.nodeParent,
            nodeLevel: this.state.dataModal.nodeLevel,
            metadata_group_code: "",
            role_access_code: "",
            max_file_size: "",
            file_extension: "",
        };

        this.setState({ dataModal: data });
    }

    private afterOpenModal(e: any) {

        var data = {
            isOpenModal: false,
            mode: this.state.dataModal.mode,
            nodeCode: this.state.dataModal.nodeCode,
            folderName: this.state.dataModal.folderName,
            nodeParent: this.state.dataModal.nodeParent,
            nodeLevel: this.state.dataModal.nodeLevel,
            metadata_group_code: "",
            role_access_code: "",
            max_file_size: "",
            file_extension: "",
        };

        this.setState({ dataModal: data });
    }

    private afterModalSaveSuccess(e: any) {
        this.ensureDataFetched();
    }

    onSelectFolder = (e: any) => {
        e.target.blur();
        const nodeCode = e.target.dataset.nodecode;

        this.setState(prevState => {
            const nextExpandNodes = [...prevState.expandNodes];
            const removePosition = nextExpandNodes.indexOf(nodeCode);

            if (removePosition > -1) {
                nextExpandNodes.splice(removePosition, 1);
            } else {
                nextExpandNodes.push(nodeCode);
            }

            return {
                expandNodes: [...new Set(nextExpandNodes)]
            };
        });

        this.props.onSelectFolder(
            e.target.dataset.fullpath,
            nodeCode,
            e.target.dataset.nodename,
            e.target.dataset.metadata,
            e.target.dataset.sid
        );
    }

    private checkUserFolderAuth(nodeCode: string, authId: string): boolean {
        const folderUserAuth: UserAuthMappingFolderTreeCheckBox[] = this.props.folderUserAuth;

        const foundUserAuth = folderUserAuth.find(role => role.node_code === nodeCode && role.auth_ids?.split('|').includes(authId));

        return (foundUserAuth) ? true : false;
    }

    private defaultExpandFolder(folderList: Models.FolderMap[]): string[] {
        const folderUserAuth: UserAuthMappingFolderTreeCheckBox[] = this.props.folderUserAuth;

        const resolvePath = (targetNodeCode: string, nodeList: Models.FolderMap[], paths: string[] = []): string[] => {
            for (const node of nodeList) {
                paths.push(node.node_code);
                if (node.node_code !== targetNodeCode && node.list_node.length > 0) {
                    resolvePath(targetNodeCode, node.list_node, paths);
                } else if (
                    node.node_code !== targetNodeCode &&
                    node.list_node.length === 0
                ) {
                    paths.pop();
                    continue;
                }
                if (paths.includes(targetNodeCode)) {
                    return paths;
                } else {
                    paths.pop();
                }
            }

            return [];
        };

        const expandNodes = folderUserAuth.reduce<string[]>((accumulate, currentNode) => {
            const paths = resolvePath(currentNode.node_code!, folderList);

            return accumulate.concat(paths);
        }, []);

        return [...new Set(expandNodes)];
    }

    renderNode(item: Array<Models.FolderMap>) {
        const checkBoxStyle: React.CSSProperties = {
            width: '20px',
            height: '20px',
            marginRight: '30px',
        }

        return item.map((item: Models.FolderMap, index: number) => {
            const hasChildNode = item.list_node.length > 0;
            return (
                <StyledTreeItem
                    key={item.node_code}
                    nodeId={item.node_code}
                    labelText={item.folder_name}
                    labelIcon={FolderIcon}
                    labelTextcount={
                        <Row>
                            {DefaultRoleAuthorize !== undefined &&
                                DefaultRoleAuthorize.length > 0 &&
                                DefaultRoleAuthorize.map((auth, index) => {
                                    return (
                                        <Col md={2} key={index}>
                                            <input
                                                disabled={this.props.disabledCheckbox}
                                                type="checkbox"
                                                style={checkBoxStyle}
                                                name={auth.label}
                                                value={auth.value}
                                                checked={this.checkUserFolderAuth(item.node_code, auth.value)}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={() => this.props.changeFolderUserAuth(item.node_code, auth.value)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    }
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    style={{ paddingLeft: "10px" }}
                    data-sid={item.sid}
                    data-fullpath={item.full_path}
                    data-nodecode={item.node_code}
                    data-nodename={item.folder_name}
                    data-nodeparent={item.node_parent}
                    data-nodelevel={item.node_level}
                    data-metadata={item.metadata_group_code}
                    onContextMenu={this.onContextMenuClick}
                    iconImg={
                        (hasChildNode)
                            ? FolderImg
                            : BoxIDImg
                    }
                >
                    {hasChildNode && this.renderNode(item.list_node)}
                </StyledTreeItem>
            );
        });
    }

    render() {
        const folderHaveRolesAccess = this.defaultExpandFolder(this.state.listFolder);
        //console.log("[Debug] folderHaveRolesAccess", folderHaveRolesAccess)

        return (
            <div className="div-folder-main">
                <TreeView
                    // expanded={(folderHaveRolesAccess.length !== 0) ? folderHaveRolesAccess : []}
                    expanded={[...new Set([...this.state.expandNodes, ...folderHaveRolesAccess])]}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    onFocus={this.onSelectFolder}
                >
                    {!this.state.isLoadSuccess &&
                        <div className="text-center mt-5">
                            <label><Spinner size="sm" color="secondary" /> Loading...</label>
                        </div>
                    }
                    {this.state.isLoadSuccess && this.state.listFolder.length === 0 &&
                        <div className="text-center mt-5">
                            <h6><ErrorOutlineIcon /> ไม่ได้รับอนุมัติให้ใช้งาน</h6>
                        </div>
                    }
                    {this.state.isLoadSuccess && this.state.listFolder.length !== 0 && this.renderNode(this.state.listFolder)}
                </TreeView>

                <Menu id={MENU_ID} style={{ fontSize: "12px" }}>
                    <Item disabled>
                        <span>{this.state.dataModal.nodeCode}</span>
                        <CopyToClipboard text={this.state.dataModal.nodeCode} >
                            <Button style={{ right: "5px", width: "30px", height: "30px", padding: "0", marginLeft: '12px' }}>
                                <i className="fas fa-copy"></i>
                            </Button>
                        </CopyToClipboard>
                    </Item>

                    {/* {!(dataModal.nodeCode.includes("S-")) && */}
                    {/*<React.Fragment>*/}
                    {/*    <Separator />*/}
                    {/*    <Item data-mode="add" onClick={this.onClickMoreFolder}>*/}
                    {/*        <img src={AddImg} alt="menu-delete" style={{ marginRight: "5px", width: "20px" }} />*/}
                    {/*        Add*/}
                    {/*    </Item>*/}
                    {/*    <Item data-mode="edit" onClick={this.onClickMoreFolder}>*/}
                    {/*        <img src={EditImg} alt="menu-delete" style={{ marginRight: "5px", width: "20px" }} />*/}
                    {/*        Edit*/}
                    {/*    </Item>*/}
                    {/*    <Item data-mode="delete" onClick={this.onClickMoreFolder}>*/}
                    {/*        <img src={DeleteImg} alt="menu-delete" style={{ marginRight: "5px", width: "20px" }} />*/}
                    {/*        Delete*/}
                    {/*    </Item>*/}
                    {/*</React.Fragment>*/}
                    {/* } */}

                </Menu>

                {/*<ModalManageFolderComponent*/}
                {/*    detailFolder={this.state.detailFolder}*/}
                {/*    valueCodeAndName={this.props.valueCodeAndName}*/}
                {/*    dataModal={dataModal}*/}
                {/*    afterOpenModal={this.afterOpenModal.bind(this)}*/}
                {/*    afterModalSaveSuccess={this.afterModalSaveSuccess.bind(this)}*/}
                {/*/>*/}

            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.folderMap, ...state.appState }),
    ({ ...Stores.actionCreators, /*...StoresFolder.actionCreators*/ })
)(FoldersComponentForUserFolderAuthorization);
