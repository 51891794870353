import React, { createContext, PropsWithChildren, useState } from "react";

import { UserRoleAccessModel } from '../models/RoleAccess';

export type AdminContextType = {
    userRole: UserRoleAccessModel[];
    setUserRole: (value: UserRoleAccessModel[]) => void;
};

export const AdminContext = createContext<AdminContextType | null>(null);

function AdminProvider({ children }: PropsWithChildren<{}>) {
    const [userRole, setUserRole] = useState<UserRoleAccessModel[]>([]);

    const provide = {
        userRole,
        setUserRole
    };

    return (
        <AdminContext.Provider value={provide}>
            {children}
        </AdminContext.Provider>
    );
}

export default AdminProvider;
