import Pagination from '@material-ui/lab/Pagination';
import * as React from 'react';
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import imgFile from '../../Images/Icon/icon-File.png';
import imgImage from '../../Images/Icon/icon-image.png';
import imgPDF from '../../Images/Icon/icon-PDF.png';
import imgPowerPoint from '../../Images/Icon/icon-PowerPoint.png';
import imgWord from '../../Images/Icon/icon-Word.png';
import imgXLS from '../../Images/Icon/icon-XLS.png';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import * as Models from '../../models/File';
import * as ModelsMeta from '../../models/File';
import * as ModelsMetaData from '../../models/MetaData';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/File';
import * as StoresMetaData from '../../store/FileDetail';

import * as CheckCompanyUserStore from '../../store/CheckCompanyUser'
import * as CheckCompanyUserModels from '../../models/Employee'

import * as MasterCompanyStore from '../../store/CompanyMaster'
import * as MasterCompanyModels from '../../models/CompanyMaster'

import FileDetailComponent from './FileDetailComponent';
import TablePagination from './TablePaginationComponent';
import InfinitePagination from '../common/InfinitePagination';
import { debounce, convertSearchKey } from '../../modules/common';
import { IUserContext, UserContext } from '../../context/UserProvider';

interface Props {
    [key: string]: any;
    folderSelectedCode: string;
    getValueFileList: (_?: string) => void;
}

interface States {
    checkMeta: boolean,
    isLoadCompanyUser: boolean,
    checkResponseTable: boolean,
    checkRequest: boolean,
    pageTab: any,
    pageTable: any,
    pageSize: any,
    pageBtn: any,
    page: number,
    sendValueFile: any,
    checkboxChecked: boolean,
    valueBatchAll: any,
    pageLoad: boolean,
    modelListFile: any,
    nodeCode: string,
    version: number,
    isNewLoad: boolean,
    isModalDetailOpen: boolean,
    isModalMetaDataOpen: boolean,
    fileSelected: Models.File,
    listFileMetaData: Array<ModelsMetaData.MetaData>,
    isSelected: Array<Models.File>,
    list_company: any;
    searchKey: string;
    doLocalSearch: boolean;
    doFormatFilesRespons: boolean;
}

class FilesComponent extends React.PureComponent<Props, States> {
    static contextType: React.Context<any> | undefined = UserContext;

    constructor(props: any) {
        super(props);

        this.state = {
            pageTable: 1,
            pageTab: '',
            pageBtn: [],
            pageLoad: true,
            modelListFile: [],
            nodeCode: "",
            version: 0,
            isNewLoad: false,
            checkRequest: true,
            checkResponseTable: true,
            isModalDetailOpen: false,
            isModalMetaDataOpen: false,
            fileSelected: {
                id: "",
                primary_index: "",
                version: 0,
                verify: false,
                index_string: "",
                role_access: "",
                file_name: "",
                file_path: "",
                full_path: "",
                created_by: "",
                created_on: "",
                updated_by: "",
                updated_on: "",
                sid: "",
                metadata: "",
                bucket: "",
                group_id: "",
                sub_group_id: "",
                relation: "",
                box_id: ""
            },
            listFileMetaData: [],
            isSelected: [],
            valueBatchAll: [],
            checkboxChecked: false,
            sendValueFile: [],
            page: 1,
            pageSize: 25,
            checkMeta: false,
            isLoadCompanyUser: true,
            list_company: [],
            searchKey: '',
            doLocalSearch: false,
            doFormatFilesRespons: false,
        }

        this.BeforehandleChangePage = this.BeforehandleChangePage.bind(this)
        this.previewFile = this.previewFile.bind(this);
        this.handlePageSize = this.handlePageSize.bind(this)
        this.handleSubmitSearchFileName = this.handleSubmitSearchFileName.bind(this);
    }

    public componentDidMount() {
        const { checkValueFile, valueModelFile } = this.props
        if (checkValueFile === true) {
            this.formatData(valueModelFile)
            this.setState({
                isNewLoad: false
            });
            this.props.resetLoading("reset")
        } else {
            //this.ensureDataFetched();
            window.addEventListener("message", (event) => {
                if (event.origin !== window.location.origin) {
                    this.getValueFileList("get")
                    window.parent.postMessage(this.state.sendValueFile, event.origin);
                }
            }, false);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {
            checkValueFile,
            valueModelFile,
            statusEmployee,
            isLoadingEmployee,
            response
        } = this.props;
        const { doLocalSearch, doFormatFilesRespons } = this.state;

        if (this.props.checkRefresh === true) {

            this.setState({
                modelListFile: [],
                nodeCode: this.props.folderSelectedCode
            });
            this.props.resetoncheckRefresh("reset")
            setTimeout(() => {
                this.ensureDataFetched();
            }, 1);
        }
        if (checkValueFile === true) {
            this.formatData(valueModelFile)
            this.setState({
                isNewLoad: false
            });
            this.props.resetLoading("reset")
        }
        if (this.props.isLoadingDetail && this.state.checkMeta === true) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'Save Success',
                icon: 'success'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.toggleModalMetaData()
                    this.setState({
                        checkMeta: false,
                    });
                    this.afterSaveModalDetail()
                }
            })
        }

        if (isLoadingEmployee && statusEmployee === 200 && this.state.isLoadCompanyUser) {
            this.ensureDataFetched();
            this.setState({
                isLoadCompanyUser: false
            })
        }

        // NGAME START INSTRUCTIONS: When local search working
        if (doLocalSearch) {
            this.searchFileName(this.state.searchKey);
            this.setState({ doLocalSearch: false, doFormatFilesRespons: true });
        }

        if (doFormatFilesRespons && prevProps.response !== response) {
            this.formatData(response);
            this.setState({ doFormatFilesRespons: false, isNewLoad: false });
        }
        // NGAME INSTRUCTION ENDED
    }

    private searchFileName(fileName: string) {
        this.props.requestFunction(this.state.pageLoad, fileName, false, this.state.nodeCode, this.state.version, this.state.page, 25, this.state.list_company, fileName);
    }

    private ensureDataFetched() {
        if (this.state.nodeCode.length === 0) {
            return;
        }

        const { responseEmployee, isLoadingEmployee, user } = this.props
        if (this.state.checkMeta === true) {
            this.setState({ pageLoad: true });
        } else {
            if (!this.state.pageLoad && !this.state.isNewLoad) {
                this.setState({ pageLoad: true, isNewLoad: true });
            }
        }

        var list_company_user: any = []
        if (isLoadingEmployee && responseEmployee.result_list !== undefined && responseEmployee.result_list.length !== 0) {
            for (var i = 0; i < responseEmployee.result_list.length; i++) {
                if (responseEmployee.result_list[i].id.toLocaleLowerCase() === user.toLocaleLowerCase()) {
                    var data = responseEmployee.result_list[i]
                    for (var j = 0; j < data.list_company.length; j++) {
                        list_company_user.push(data.list_company[j].sid)
                    }
                }

            }
            //console.log('list_company_user')
            //console.log(list_company_user)
        }

        this.props.requestFunction(this.state.pageLoad, convertSearchKey(this.state.searchKey), false, this.state.nodeCode, this.state.version, this.state.page, 25, list_company_user);
        this.setState({ pageLoad: false, list_company: list_company_user });
    }

    getValueFileList = (check) => {
        if (check === "get") {
            var node = "node"
            var wfList: any = [];
            Array.from(document.querySelectorAll("input[name=" + node + "]"))
                .forEach((checkbox) => {
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb.checked) {
                        if (cb.dataset.filelist) {
                            var filelist = JSON.parse(cb.dataset.filelist)
                            wfList.push(filelist)
                        }
                    }
                    this.setState({ sendValueFile: wfList })
                })
        }
    }

    private highlight(name, search, id, idArray) {
        if (name !== "" && name !== undefined) {
            // const parts = name.split(new RegExp(`(${search})`, 'gi'));
            return <Input type="text" onChange={(e) => this.handleChange(e, "Name", idArray)} defaultValue={name} style={{ width: "100%", height: "22px", display: "inline", fontSize: "12px" }} />
        } else {
            return null
        }
    }

    private previewFile(item: Models.File) {

        if (item.file_name.indexOf('.jpg') !== -1 || item.file_name.indexOf('.png') !== -1) {
            return imgImage;
        } else if (item.file_name.indexOf('.pdf') !== -1) {
            return imgPDF;
        } else if (item.file_name.indexOf('.xls') !== -1 || item.file_name.indexOf('.xlsx') !== -1) {
            return imgXLS;
        } else if (item.file_name.indexOf('.doc') !== -1 || item.file_name.indexOf('.docx') !== -1) {
            return imgWord;
        } else if (item.file_name.indexOf('.ppt') !== -1 || item.file_name.indexOf('.pptx') !== -1) {
            return imgPowerPoint;
        } else {
            return imgFile;
        }
    }

    onDetailClick = (e: any, list: number, list_sub: number, list_detail: number) => {
        this.setState({
            fileSelected: this.state.modelListFile[0][list].sub_group_detail[list_sub].sub_group_id_detail.filter(a => a.id === e.currentTarget.dataset.id)[0],
            isModalDetailOpen: true
        })
    }

    afterOpenModalDetail = () => {
        this.setState({ isModalDetailOpen: false })
    }

    afterSaveModalDetail = () => {
        this.setState({ modelListFile: [] })
        this.ensureDataFetched();
    }

    onMetaDataClick = (e: any, list: number, list_sub: number, list_detail: number) => {

        var metaData: string[] = this.state.modelListFile[0][list].sub_group_detail[list_sub].sub_group_id_detail.filter(a => a.id === e.currentTarget.dataset.id)[0].metadata.split("|");
        var listMetaData: Array<ModelsMetaData.MetaData> = [];

        for (var i = 0; i < metaData.length; i++) {
            var subMetaData = {
                metadata_code: metaData[i].split(":")[0],
                metadata_name: metaData[i].split(":")[1]
            }
            listMetaData.push(subMetaData as ModelsMetaData.MetaData);
        }

        this.setState({
            fileSelected: this.state.modelListFile[0][list].sub_group_detail[list_sub].sub_group_id_detail.filter(a => a.id === e.currentTarget.dataset.id)[0],
            isModalMetaDataOpen: true,
            listFileMetaData: listMetaData
        })
    }

    toggleModalMetaData = () => {
        this.setState({ isModalMetaDataOpen: !this.state.isModalMetaDataOpen })
    }

    //onSelectedFile = (e: any) => {
    //    //debugger;
    //    //console.log(e.target.checked);
    //    //console.log(e.currentTarget.dataset.id);


    //    var itemSelect = this.state.isSelected as Array<Models.File>
    //    var itemFile = this.state.modelListFile as Array<Models.File>
    //    debugger;
    //    if (e.target.checked) {
    //        //Models.File
    //        var item = this.state.modelListFile.filter(a => a.id === e.currentTarget.dataset.id)[0] as Models.File
    //        itemSelect.push(item);
    //        for (var i = 0; i < itemFile.length; i++) {
    //            if (itemFile[i].id === e.currentTarget.dataset.id) {
    //                itemFile[i].is_selected = true;
    //            }
    //        }

    //    } else {
    //        itemSelect = this.state.isSelected.filter(a => a.id !== e.currentTarget.dataset.id) as Array<Models.File>
    //        for (var i = 0; i < itemFile.length; i++) {
    //            if (itemFile[i].id === e.currentTarget.dataset.id) {
    //                itemFile[i].is_selected = false
    //            }
    //        }
    //    }

    //    this.setState({
    //        isSelected: itemSelect,
    //        modelListFile: itemFile
    //    })
    //    console.log(itemSelect);
    //    console.log("itemFile",itemFile);

    //}

    /*onSelectedFile = (e: any) => {

        //var itemSelect = this.state.isSelected as Array<Models.File>
        var itemFile = this.state.modelListFile as Array<Models.File>
        if (e.target.checked) {

            for (var i = 0; i < itemFile.length; i++) {
                if (itemFile[i].id === e.currentTarget.dataset.id) {
                    itemFile[i].is_selected = true;
                }
            }
            //var item = this.state.modelListFile.filter(a => a.id === e.currentTarget.dataset.id)[0] as Models.File
            //itemSelect.push(item);

        } else {
            for (var i = 0; i < itemFile.length; i++) {
                if (itemFile[i].id === e.currentTarget.dataset.id) {
                    itemFile[i].is_selected = false
                }
            }
            //itemSelect = this.state.isSelected.filter(a => a.id !== e.currentTarget.dataset.id) as Array<Models.File>

        }

        this.setState({
            isSelected: [],
            modelListFile: itemFile
        })
        console.log("itemFile", itemFile);

        setTimeout(() =>
            this.mapFileToFileSected()
            , 250
        );
    }

    onCheckedAll = (e: any) => {
        var itemFile = this.state.modelListFile as Array<Models.File>

        if (e.target.checked) {
            for (var i = 0; i < itemFile.length; i++) {
                itemFile[i].is_selected = true;
            }
            this.setState({
                isSelected: [],
                modelListFile: itemFile
            })
        } else {
            for (var i = 0; i < itemFile.length; i++) {
                itemFile[i].is_selected = false;
            }
            this.setState({
                isSelected: [],
                modelListFile: itemFile
            })
        }
        setTimeout(() => {
            this.mapFileToFileSected();
            console.log(this.state.isSelected)
        }, 250
        );

    }

    mapFileToFileSected = () => {
        var itemSelect = this.state.isSelected as Array<Models.File>
        var itemFile = this.state.modelListFile as Array<Models.File>

        for (var i = 0; i < itemFile.length; i++) {
            if (itemFile[i].is_selected) {
                itemSelect.push(itemFile[i]);
            }
        }

        this.props.GetDataSelectFile(itemSelect);
    }*/

    formatData(value) {
        this.handleChangePage(this.state.page, '')
        var valueBatchreverse: any = []
        var valuecheckdate: any = []
        var valuecheckdateUse: any = []
        var uniqueBatchIdUse: any = [];

        if (value !== undefined && value['result_list'] !== undefined) {
            value['result_list'].map(groupId => {
                if (uniqueBatchIdUse.indexOf(groupId.group_id) === -1) {
                    uniqueBatchIdUse.push(groupId.group_id)
                }
            });
        }
        else {
            value !== undefined && value.map(groupId => {
                if (uniqueBatchIdUse.indexOf(groupId.group_id) === -1) {
                    uniqueBatchIdUse.push(groupId.group_id)
                }
            });
        }

        var checkGroup: any = []
        uniqueBatchIdUse.map((item) => {
            if (item === "" || item === null) {
                checkGroup.push(item)
            }
        })

        if (checkGroup.length > 1) {
            const index = uniqueBatchIdUse.indexOf(null);
            if (index > -1) {
                uniqueBatchIdUse.splice(index, 1);
            }
        }

        valuecheckdate = uniqueBatchIdUse.sort();
        valuecheckdateUse = valuecheckdate.reverse()

        for (let f = 0; f < valuecheckdateUse.length; f++) {
            if (valuecheckdateUse[f] !== "" && String(parseInt(valuecheckdateUse[f])) !== "NaN") {
                valueBatchreverse.push(valuecheckdateUse[f])
            }
        }

        for (let g = 0; g < valuecheckdateUse.length; g++) {
            if (String(parseInt(valuecheckdateUse[g])) === "NaN" && valuecheckdateUse[g] !== "") {
                valueBatchreverse.push(valuecheckdateUse[g])
            }
        }

        for (let f = 0; f < valuecheckdateUse.length; f++) {
            if (valuecheckdateUse[f] === "") {
                valueBatchreverse.push(valuecheckdateUse[f])
            }
        }

        var uniqueSubUse: any = []
        valueBatchreverse.map((item, id) => {
            var uniqueSub: any = []
            if (value['result_list'] !== undefined) {
                value['result_list'].map((items, ids) => {
                    let data = {}
                    if (item === items.group_id) {
                        data['sub_group_id'] = items.sub_group_id
                        data['group_id'] = item
                        uniqueSub.push(data)
                    }
                })
            } else {
                value.map((items, ids) => {
                    let data = {}
                    if (item === items.group_id) {
                        data['sub_group_id'] = items.sub_group_id
                        data['group_id'] = item
                        uniqueSub.push(data)
                    }
                })
            }

            let result = uniqueSub.filter((value, index, self) => self.findIndex((m) => m.sub_group_id === value.sub_group_id) === index);
            uniqueSubUse.push(result)
        })

        uniqueSubUse.map((data, id) => {
            var posiDel: number = 0
            var checlDel: any = []
            data.map((data_detail, id_detail) => {
                if (data_detail.sub_group_id === "" || data_detail.sub_group_id === null) {
                    checlDel.push(data_detail.sub_group_id)
                    posiDel = id_detail
                }
            })
            if (checlDel.length > 1) {
                delete uniqueSubUse[id][posiDel]
            }
        })

        var detailGroup: any = []
        valueBatchreverse.map((item, id) => {
            var detailInGroup: any = []
            var count_group: number = 0
            uniqueSubUse[id].map((items, ids) => {
                if (item === items.group_id) {
                    let data = {}
                    var nameSub: any = ""
                    if (items.sub_group_id === "" || items.sub_group_id === null || items.sub_group_id === undefined) {
                        nameSub = "NONE"
                    } else {
                        nameSub = items.sub_group_id
                    }

                    var itemGroup: any = ''
                    if (items.group_id === '' || items.group_id === null) {
                        itemGroup = ''
                    } else {
                        itemGroup = items.group_id
                    }

                    var itemGroupDetail: any = ''
                    if (items.sub_group_id === '' || items.sub_group_id === null) {
                        itemGroupDetail = ''
                    } else {
                        itemGroupDetail = items.sub_group_id
                    }

                    data['sub_group_id'] = nameSub
                    var dataDetail: any = []
                    var count_sub_group_id: number = 0
                    if (value['result_list'] !== undefined) {
                        value['result_list'].map((item_detail, id_detail) => {

                            var itemSubGroup: any = ''
                            if (item_detail.group_id === '' || item_detail.group_id === null) {
                                itemSubGroup = ''
                            } else {
                                itemSubGroup = item_detail.group_id
                            }

                            var itemSubGroupDetail: any = ''
                            if (item_detail.sub_group_id === '' || item_detail.sub_group_id === null) {
                                itemSubGroupDetail = ''
                            } else {
                                itemSubGroupDetail = item_detail.sub_group_id
                            }

                            if (itemSubGroupDetail === itemGroupDetail && itemSubGroup === itemGroup) {
                                dataDetail.push(item_detail)
                                count_sub_group_id += 1
                                count_group += 1
                            }
                        })
                    } else {
                        value.map((item_detail, id_detail) => {
                            var itemSubGroup: any = ''
                            if (item_detail.group_id === '' || item_detail.group_id === null) {
                                itemSubGroup = ''
                            } else {
                                itemSubGroup = item_detail.group_id
                            }

                            var itemSubGroupDetail: any = ''
                            if (item_detail.sub_group_id === '' || item_detail.sub_group_id === null) {
                                itemSubGroupDetail = ''
                            } else {
                                itemSubGroupDetail = item_detail.sub_group_id
                            }

                            if (itemSubGroupDetail === itemGroupDetail && itemSubGroup === itemGroup) {
                                dataDetail.push(item_detail)
                                count_sub_group_id += 1
                                count_group += 1
                            }
                        })
                    }
                    data['sub_group_id_detail'] = dataDetail
                    data['count_sub_group_id'] = count_sub_group_id
                    detailInGroup.push(data)
                }
            })

            var nameGroup: any
            if (item === "" || item === null || item === undefined) {
                nameGroup = "NONE"
            } else {
                nameGroup = item
            }
            var datatotal = {
                count_group_id: count_group,
                group_id: nameGroup,
                sub_group_detail: detailInGroup
            }
            detailGroup.push(datatotal)
        })

        // this.state.modelListFile.push(detailGroup)

        if (value !== undefined && value['undefined'] === undefined) {
            this.setState({
                modelListFile: [detailGroup]
            })
        }
    }

    handleChangePage = (pageNo: number, pageSize: any) => {
        var pagesizeUse: any
        if (pageSize !== "") {
            pagesizeUse = pageSize
        } else {
            pagesizeUse = this.state.pageSize
        }
        var countingPage: any = 0
        var dataArr: any = []
        countingPage = Math.ceil(500 / this.state.pageSize)

        for (let i = 0; i < countingPage; i++) {
            var data: any = {}
            data['page'] = i + 1
            dataArr.push(data)
        }

        this.setState({
            page: pageNo,
            pageBtn: dataArr,
            checkRequest: true,
            modelListFile: [],
            checkResponseTable: true
        })
        this.setState({ pageLoad: true, isNewLoad: true });

        var searchKey = this.state.searchKey;
        this.props.requestFunction(this.state.pageLoad, convertSearchKey(searchKey), false, this.state.nodeCode, this.state.version, pageNo, pagesizeUse, this.state.list_company);

        this.setState({ pageLoad: false });
    }

    BeforehandleChangePage(_event: React.ChangeEvent<unknown>, page: number) {
        return this.handleChangePage(page, '')
    }

    handlePageSize(e) {
        this.setState({ pageSize: e.target.value })
        this.handleChangePage(this.state.page, e.target.value)
    }

    handleChange(e, check, id) {
        var data = this.state.listFileMetaData
        if (check === "ID") {
            data[id].metadata_code = e.target.value
        } else if (check === "Name") {
            data[id].metadata_name = e.target.value
        }
        this.setState({ listFileMetaData: data })
    }

    saveMetaData = () => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        var DataUse = this.state.fileSelected
        var Data: any = ''
        this.state.listFileMetaData.map((item, id) => {
            if (id === 0) {
                Data = item.metadata_code + ':' + item.metadata_name
            } else {
                Data += '|' + item.metadata_code + ':' + item.metadata_name
            }
        })
        DataUse.metadata = Data
        this.setState({ fileSelected: DataUse })
        setTimeout(() => {
            this.setState({ checkMeta: true })
            this.props.requestFunctionMeta(this.state.pageLoad, this.state.version, "PUT", this.state.fileSelected);
        }, 100);
    }

    public handleSubmitSearchFileName(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();

        // const { availableCompanies }: IUserContext = this.context;
        // const searchKey = this.state.searchKey;
        // this.props.requestFunction(this.state.pageLoad, convertSearchKey(searchKey), false, this.state.nodeCode, this.state.version, this.state.page, 25, this.state.list_company);

        this.setState({ doLocalSearch: true });
    }

    render() {
        //console.log(this.props.responseCompanyMaster);

        var { fileSelected, isModalDetailOpen } = this.state;
        const { SearchData, checkFile, checkValueFile, responseCompanyMaster } = this.props
        const { pageSize } = this.state;
        const isFromSearch = SearchData !== undefined;

        if (this.props.isReLoadFile) {
            this.ensureDataFetched();
        }

        if (this.props.version > this.state.version) {
            if (this.props.version - this.state.version === 2 && this.state.checkMeta === false) {
                if (this.props.response.total_count > 0) {

                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                    this.formatData(this.props.response)
                    this.setState({
                        isNewLoad: false
                    });
                } else {
                    this.setState({
                        modelListFile: [],
                        isNewLoad: false
                    });
                }

            }
            this.setState({ version: this.props.version });
        }

        if (this.state.nodeCode !== this.props.folderSelectedCode && checkFile === true) {
            this.setState({
                page: 1,
                modelListFile: [],
                nodeCode: this.props.folderSelectedCode
            });
            this.props.resetCheckFile("reset")
            setTimeout(() => {

                this.ensureDataFetched();
            }, 200);
        }

        const responseCount = this.props?.response?.total_count || pageSize - 1;
        const disabledNext = responseCount < pageSize;

        return (
            <div>
                <Row>
                    <Col xs={6}>
                        <Row className="d-flex align-items-center">
                            <Col>
                                <div className='d-flex justify-content-start my-2 my-lg-0'>
                                    <Form inline>
                                        <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                            Show
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            className="my-1 mr-sm-2"
                                            id="inlineFormCustomSelectPref"
                                            custom
                                            size="sm"
                                            value={this.state.pageSize}
                                            onChange={this.handlePageSize}
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </Form.Control>
                                        <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                            entries
                                        </Form.Label>
                                    </Form>
                                </div>
                            </Col>
                            {/* {
                                this.props.folderSelectedCode
                                && this.props.folderSelectedCode.length > 0
                                && ( */}
                            <Col className="d-flex align-items-center">
                                <Form onSubmit={this.handleSubmitSearchFileName}>
                                    <FormGroup inline className="m-0">
                                        <Input
                                            bsSize="sm"
                                            placeholder="Search in folder"
                                            name="searchFileName"
                                            onChange={event => this.setState({ searchKey: event.target.value })}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                            {/* )
                            } */}
                        </Row>
                    </Col>
                    {/* <Col xs={6}>
                        <div className='d-flex justify-content-end my-2 my-lg-0'>
                            <Pagination
                                // count={(this.state.pageBtn.length !== 0 && this.state.pageBtn.length) ? 1 : 0} 
                                count={10}
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                onChange={this.BeforehandleChangePage}
                                defaultPage={1}
                                siblingCount={0}
                            />
                        </div>
                    </Col> */}
                    <Col xs={6} className="d-flex justify-content-end">
                        <InfinitePagination
                            disabledNext={disabledNext}
                            page={this.state.page}
                            onChangePage={(_event, page) => this.handleChangePage(page, this.state.pageSize)}
                        />
                    </Col>
                </Row>
                <div className="form-group div-main-style">
                    <TablePagination
                        onDetailClick={this.onDetailClick.bind(this)}
                        onMetaDataClick={this.onMetaDataClick.bind(this)}
                        SearchData={this.props.SearchData}
                        checkValueFile={checkValueFile}
                        isNewLoad={this.state.isNewLoad}
                        valueModelFile={this.props.valueModelFile}
                        modelListFile={this.state.modelListFile}
                        company={responseCompanyMaster}
                        getValueFileList={this.props.getValueFileList}
                    />
                </div>

                <FileDetailComponent
                    checkMeta={this.state.checkMeta}
                    fileSelected={fileSelected}
                    isModalDetailOpen={isModalDetailOpen}
                    afterOpenModalDetail={this.afterOpenModalDetail.bind(this)}
                    afterSaveModalDetail={this.afterSaveModalDetail.bind(this)}
                    strictMode={this.props.strictMode}
                />

                <Modal isOpen={this.state.isModalMetaDataOpen} className="modal-file-meta-data">
                    <ModalHeader toggle={this.toggleModalMetaData} className="show-metadata">
                        Metadata <span style={{ color: "#117a8b" }}>{this.state.fileSelected.file_name}</span>
                    </ModalHeader>
                    <ModalBody toggle={this.toggleModalMetaData}>
                        <div className="div-table" >
                            <div className='trapezoid' style={{ width: 'auto', display: 'inline-block', marginLeft: '0px' }}>
                                <span style={{ lineHeight: '0px' }} className="show-metadata" >
                                    {this.state.fileSelected.file_name}
                                </span>
                            </div>
                            <table className='table custom-table'>
                                <thead>
                                    <tr>
                                        <th className="td-id" style={{ maxWidth: '300px' }}>Id</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.listFileMetaData.length > 0 && this.state.listFileMetaData.map((item: ModelsMetaData.MetaData, id) =>
                                        <tr key={item.metadata_code}  >
                                            <td className="td-id" style={{ maxWidth: '150px' }} >
                                                {item.metadata_code !== "" && item.metadata_code !== undefined ?
                                                    <Input type="text" defaultValue={item.metadata_code} onChange={(e) => this.handleChange(e, "ID", id)} style={{ width: "100%", height: "22px", display: "inline", fontSize: "12px" }} />
                                                    : null}
                                            </td>
                                            {SearchData !== undefined && SearchData !== "" ?
                                                <td className="td-id" >{this.highlight(item.metadata_name, SearchData, item.metadata_code, id)}</td>
                                                :
                                                <td className="td-id" >
                                                    {
                                                        item.metadata_name !== undefined && item.metadata_name !== "" ?
                                                            <Input type="text" defaultValue={item.metadata_name} onChange={(e) => this.handleChange(e, "Name", id)} style={{ width: "100%", height: "22px", display: "inline", fontSize: "12px" }} />
                                                            : null
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="success" onClick={this.saveMetaData}>Save</Button>
                        <Button variant="secondary" className="show-metadata" onClick={this.toggleModalMetaData}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) =>
    ({ ...state.file, ...state.fileDetail, ...state.CheckCompanyUser, ...state.companyMaster }),
    ({ ...Stores.actionCreators, ...StoresMetaData.actionCreators }))
    (FilesComponent)

