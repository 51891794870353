import React from "react";
import { useHistory } from "react-router-dom";

export async function onClickTr(e: any) {
    var id = e.target.dataset.id
    var fileName = e.target.dataset.filename
    var env = await getEnv()
    var ls: any = localStorage.getItem("DMS_APPLICATION");
    if (ls) {
        var jls: any = JSON.parse(ls)
        var token = jls.access_token

        ////var path = env.apiUrl + '/v1/dms/file?id=' + id + '&token=' + token
        //var path = window.location.origin + '/secure-view?id=' + id + '&token=' + token
        //if (fileName) {
        //    path += '&file_name=' + fileName
        //}
        //path = window.location.origin + '/v1/auth/login'
        //var extraWindow = window.open(
        //    path,
        //    '_blank'
        //);
        //if (extraWindow) {
        //    extraWindow['yourHiddenValues'] = { token };
        //}
        ////if (extraWindow) {
        ////    extraWindow.opener.location.reload();
        ////    extraWindow.opener = null
        ////}

        // Build formData object.
        let data = new URLSearchParams();
        data.append('id', id);
        data.append('file_name', fileName);

        fetch("v1/secure-view", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token
            },
            body: data
        })
            .then(res => res.json())
            .then(
                (result) => {
                    var queryString = result.message;
                    var path = "secure-view?key=" + queryString;
                    var extraWindow = window.open(
                        path,
                        '_blank'
                    );
                },
                (error) => {
                }
            )
    }
}

export async function onClickTrWithOutNewWindow(e: any) {
    var id = e.target.dataset.id
    var fileName = e.target.dataset.filename
    var filePath = e.target.dataset.filepath
    var env = await getEnv()

    debugger;

    var ls: any = localStorage.getItem("DMS_APPLICATION");
    if (ls) {
        var jls: any = JSON.parse(ls)
        var token = jls.access_token

        var path = env.apiUrl + '/v1/dms/file?id=' + id + '&token=' + token
        if (fileName) {
            path += '&file_name=' + fileName
        }

        window.location.href = path;
        //if (extraWindow) {
        //    extraWindow.opener.location.reload();
        //    extraWindow.opener = null
        //}
    }
}

export function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function formatYMDToDMY(dmy: string) {
    if (dmy !== undefined && dmy !== "" && dmy !== null) {
        var year = dmy.substring(0, 4);
        var month = dmy.substring(5, 7);
        var day = dmy.substring(8, 10);
        var Datevalue = "";
        var datevalue = "";
        var UTC = new Date()
        if (dmy.length < 11) {
            datevalue = day + '-' + month + '-' + year

        } else {
            var hour = dmy.substring(11, 13);
            var m = dmy.substring(14, 16);
            var s = dmy.substring(17, 19);
            datevalue = month + '/' + day + '/' + year + ' ' + hour + ':' + m + ':' + s

            let localTime = toTimestamp(datevalue).valueOf() * 1000
            let localOffset = UTC.getTimezoneOffset() * 60000;
            let utc = localTime + localOffset;
            let thai = utc + (3600000 * 14);
            let dateUTCUse = new Date(thai);
            let dateUTCUseString = dateUTCUse.toLocaleString()

            var dateSplit = dateUTCUseString.split('/');
            datevalue = dateSplit[1] + '-' + dateSplit[0] + '-' + dateSplit[2]

            datevalue = datevalue.split(',').join('  ');
        }
        return datevalue;
    } else {
        Datevalue = "";

        return Datevalue;
    }
}

function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
}

export function formatDMY(dmy: string) {

    if (dmy !== undefined && dmy !== "" && dmy !== null) {

        var dateUTC = ""

        dateUTC = dmy.split('.').join('/')
        var year = parseInt(dmy.substring(6, 10));
        var month = parseInt(dmy.substring(3, 5));
        var day = parseInt(dmy.substring(0, 2));
        var hour = parseInt(dmy.substring(11, 13));
        var m = parseInt(dmy.substring(14, 16));
        var s = parseInt(dmy.substring(17, 19));

        dateUTC = month + '/' + day + '/' + year + ' ' + hour + ':' + m + ':' + s

        var UTC = new Date()
        let localTime = toTimestamp(dateUTC).valueOf() * 1000
        let localOffset = UTC.getTimezoneOffset() * 60000;
        let utc = localTime + localOffset;
        let thai = utc + (3600000 * 14);
        let dateUTCUse = new Date(thai);
        let dateUTCUseString = dateUTCUse.toLocaleString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
        var datevalue = "";
        datevalue = dateUTCUseString.split('/').join('-')

        return datevalue.split(',').join('  ');

    } else {
        datevalue = "";

        return datevalue;
    }
}

export async function getEnv() {
    var res: any = null
    await fetch("/v1/env")
        .then(res => res.json())
        .then(
            (result) => {
                res = result
            },
            (error) => {
            }
        )

    return res
}

export async function getRedirectAppPoolUrl() {
    await fetch("/v1/env")
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result);
                window.open(result.appPoolAPI, '_self')
            },
            (error) => {
            }
        )
}

export function checkExp(e: any) {
    var exp = new Date(e.exp * 1000)
    var date_now = Date.now();
    var now = new Date(date_now)
    if (exp < now) {
        getRedirectAppPoolUrl()
    }
}

export function formatNumberOfDateToDDMMYYYY(value: number | string): string {
    const dateString = String(value);

    if (dateString.length === 8) {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const date = dateString.substring(6, 8);

        return `${date}/${month}/${year}`;
    } else {
        return '';
    }
}

type MetadataCollectionEntries = [string, string][];
export function validateMetadataCollections(metadataCollections: MetadataCollectionEntries): void {
    try {
        const collectionsLength = metadataCollections.length;

        for (let j = 0; j < collectionsLength; j++) {
            if (j === collectionsLength - 1) {
                break;
            }

            const currMetadata = metadataCollections[j];

            for (let k = j + 1; k < collectionsLength; k++) {
                const nextMetadata = metadataCollections[k];

                if (currMetadata[0] === nextMetadata[0]) {
                    throw new Error('Duplicate key');
                }
            }
        }
    } catch (error) {
        throw error;
    }
}

export function convertMetadataToEntries(metadataString: string): [string, string][] {
    if (metadataString === '' || !metadataString.includes(':')) {
        console.warn('Warning:', 'Wrong format metadata (format must be key:value)');
        return [];
    }

    const metadataEntries = metadataString
        .split('|')
        .map(metadata => {
            const entry = metadata.split(':');
            return [entry[0], entry[1]] as [string, string];
        });

    return metadataEntries;
}

export function convertMetadataToString(metadataEntries: [string, string][]): string {
    return metadataEntries
        .map(([key, value], _index) => `${key}:${value}`)
        .join('|');
}

export function convertFullpathToFolderName(fullPath: string): string {
    if (!fullPath || fullPath === '') {
        return '';
    }

    const splitedPaths = fullPath
        .split(' > ')
        .filter(path => path !== '');
    return splitedPaths[splitedPaths.length - 1];
}

export function convertSearchKey(keys: string): string {
    if (!keys || keys === '') {
        return '';
    }

    const keyword = keys.trim().split(' ').join(' AND ');

    return keyword;
}

export function debounce(callback: Function, delay: number) {
    let debounceTimer: NodeJS.Timeout;

    return function (this: any) {
        const context = this;
        const args = arguments;

        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => callback.apply(context, args), delay);
    }
}