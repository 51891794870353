import React, { useEffect } from 'react';
import {
    Route,
} from 'react-router';
import { getRedirectAppPoolUrl } from '../modules/common';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!localStorage.getItem('DMS_APPLICATION')) {
                getRedirectAppPoolUrl();

                return null;
            }

            return <Component {...props} />;
        }}
    />
);
