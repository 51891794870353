import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { Badge, Form } from 'react-bootstrap';

import InfinitePagination from '../common/InfinitePagination';
import AuthenticationHistorySearchBar, {
    SearchValues,
} from './AuthenticationHistorySearchBar';

type ListResponse<T> = {
    totalCount: number;
    resultList: T[];
};

interface AuthenticationHistory {
    citizenId: string;
    actionId: string;
    action: 'login';
    actionResponse: 'success' | 'fail';
    actionMessage?: string;
    timestamp: string;
    ipAddress: string;
    clientIPAddress: string;
    keyUUID: string;
    mode: string;
}

function AuthenticationHistoryLogs() {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    // Search
    const [citizenId, setCitizenId] = useState<string>();
    const [from, setFrom] = useState<Date | null>();
    const [to, setTo] = useState<Date | null>();

    const fromISO = useMemo(
        () => (!from ? undefined : from.toISOString()),
        [from]
    );
    const toISO = useMemo(() => (!to ? undefined : to.toISOString()), [to]);

    const { isLoading, error, data, isPlaceholderData } = useQuery({
        queryKey: [
            'auth-history',
            { page, pageSize, citizenId, fromISO, toISO },
        ],
        queryFn: () =>
            axios.get<ListResponse<AuthenticationHistory>>('/v1/auth-history', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'DMS_APPLICATION'
                    )}`,
                },
                params: {
                    page,
                    page_size: pageSize,
                    citizen_id: citizenId,
                    from_date: fromISO,
                    to_date: toISO,
                },
            }),
        select: (response) => response.data.resultList,
        keepPreviousData: true,
    });

    // Error handling
    useEffect(() => {
        if (!error) return;

        if (error instanceof AxiosError) {
            Swal.fire('Error', error.response?.data.message || 'ไม่ทราบสาเหตุ');
        } else if (error instanceof Error) {
            Swal.fire('Error', error.message);
        }
    }, [error]);

    const handleSubmitSearch = useCallback((values: SearchValues) => {
        const { citizenId, from, to } = values;

        setPage(1);

        setFrom(from);
        setTo(to);
        setCitizenId(citizenId);
    }, []);

    return (
        <div>
            <AuthenticationHistorySearchBar
                onSubmitSearch={handleSubmitSearch}
            />

            <div
                style={{ gap: 16 }}
                className="d-flex justify-content-end align-items-center"
            >
                <div
                    style={{ gap: 8 }}
                    className="d-flex align-items-center"
                >
                    <Form.Label className="text-left">Page Size</Form.Label>
                    <Select
                        options={[
                            { label: 25, value: 25 },
                            { label: 50, value: 50 },
                            { label: 100, value: 100 },
                        ]}
                        value={{
                            label: pageSize,
                            value: pageSize,
                        }}
                        onChange={(newValue) =>
                            newValue && setPageSize(newValue.value)
                        }
                    />
                </div>

                <InfinitePagination
                    disabledNext={!data || data.length === 0}
                    page={page}
                    style={{
                        padding: '0px',
                        marginTop: '16px',
                        justifyContent: 'flex-end',
                    }}
                    onChangePage={(_event, page) => setPage(page)}
                />
            </div>

            <table className="table table-bordered mt-3">
                <thead className="thead-light">
                    <tr>
                        <th>Mode</th>
                        <th>User</th>
                        <th>Action</th>
                        <th>Action Result</th>
                        <th>Action Message</th>
                        <th>Timestamp</th>
                        <th>IP Address</th>
                        <th>Client IP Address</th>
                        <th>Key UUID</th>
                    </tr>
                </thead>
                <tbody>
                    {!data || data?.length === 0 ? (
                        <tr className="text-center">
                            <td colSpan={9}>No data</td>
                        </tr>
                    ) : (
                        data.map((log) => {
                            const formattedDate = dayjs(log.timestamp).format(
                                'DD/MM/YYYY HH:mm:ss น.'
                            );

                            return (
                                <tr key={log.actionId}>
                                    <td>
                                        <Badge
                                            variant={
                                                log.mode === 'gui'
                                                    ? 'primary'
                                                    : 'info'
                                            }
                                        >
                                            <span className="relative top-[1px]">
                                                {log.mode.toUpperCase()}
                                            </span>
                                        </Badge>
                                    </td>
                                    <td>{log.citizenId}</td>
                                    <td>{log.action}</td>
                                    <td>
                                        <Badge
                                            variant={
                                                log.actionResponse === 'success'
                                                    ? 'success'
                                                    : 'danger'
                                            }
                                        >
                                            {log.actionResponse}
                                        </Badge>
                                    </td>
                                    <td>{log.actionMessage}</td>
                                    <td>{formattedDate}</td>
                                    <td>{log.ipAddress}</td>
                                    <td>{log.clientIPAddress}</td>
                                    <td>{log.keyUUID}</td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default AuthenticationHistoryLogs;
