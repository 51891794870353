import * as React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Layout from './components/Layout';
import './custom.css';

import UserProvider from './context/UserProvider';
import DefaultValueProvider from './context/DefaultValueProvider';
import AdminProvider from './context/adminProvider';

const queryClient = new QueryClient();

export default () => (
    <UserProvider>
        <DefaultValueProvider>
            <AdminProvider>
                <QueryClientProvider client={queryClient}>
                    <Layout />
                </QueryClientProvider>
            </AdminProvider>
        </DefaultValueProvider>
    </UserProvider>
);
