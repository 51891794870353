import { Reducer, Action } from 'redux';
import { AppThunkAction } from '.';
import { AuthorizeStateStore, Authorize } from '../models/Authorize';
import { ResponseList } from '../models/Common';

interface RequestAuthorize {
    type: 'REQUEST_AUTHORIZE';
}

interface ReceiveAuthorize {
    type: 'RECEIVE_AUTHORIZE';
    data: ResponseList<Authorize>;
}

interface FailureAuthorize {
    type: 'FAILURE_AUTHORIZE';
    error: any;
}

interface ClearAuthorize {
    type: 'CLEAR_AUTHORIZE',
}

type KnownAction =
    | RequestAuthorize
    | ReceiveAuthorize
    | FailureAuthorize
    | ClearAuthorize;

export const actionCreators = {
    requestGetAuthorize: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_AUTHORIZE' });

        fetch('/v1/authorize')
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    Promise.reject(response.json());
                }
            })
            .then(data => {
                const responseListAuthorize: ResponseList<Authorize> = {
                    total_count: data.totalCount,
                    result_list: data.resultList
                };

                dispatch({ type: 'RECEIVE_AUTHORIZE', data: responseListAuthorize });
            })
            .catch(error => dispatch({ type: 'FAILURE_AUTHORIZE', error }));
    }
}

const initialState: AuthorizeStateStore = {
    isLoading: false,
    response: null,
    displayAuthorize: [],
    status: 0,
    error: null
};

export const reducer: Reducer<AuthorizeStateStore> = (state = initialState, action: KnownAction) => {
    switch (action.type) {
        case 'REQUEST_AUTHORIZE': {
            return {
                ...state,
                isLoading: true
            }
        }
        case 'RECEIVE_AUTHORIZE': {
            return {
                ...state,
                isLoading: false,
                response: action.data,
                displayAuthorize: action.data.result_list.map(role => ({ label: role.name, value: role.id })),
                status: 200
            }
        }
        case 'FAILURE_AUTHORIZE': {
            return {
                ...state,
                isLoading: false,
                status: 400,
                error: action.error
            }
        }
        case 'CLEAR_AUTHORIZE': {
            return initialState;
        }
        default: return state;
    }
}