import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';
import CreatableSelect from 'react-select/creatable';

import { ApplicationState } from '../../../store';

import * as EmployeeMasterStore from '../../../store/EmployeeMaster';
import { EmployeeState, DisplayEmployee } from '../../../models/Employee';

import * as CompanyMasterStore from '../../../store/CompanyMaster';
import { CompanyMasterState, DisplayCompany } from '../../../models/CompanyMaster';

import { AdminContext, AdminContextType } from '../../../context/adminProvider';
import { FolderAuthUserLoggingResponse, FolderAuthUserLogging } from '../../../models/FolderAuthorizeUserMapping';
import { UserRoleAccessModel } from '../../../models/RoleAccess';
import { DefaultRoleAuthorize } from '../../../models/Authorize';

import ExportExcelLogModal from './ExportExcelLogModal';

interface ComponentProps {
    employee: EmployeeState;
    company: CompanyMasterState;
}

interface State {
    isLoading: boolean,
    folderAuthUserLog: FolderAuthUserLogging[],
    userOptions: SelectorOptions[],
    companyOptions: SelectorOptions[],
    userRole: UserRoleAccessModel[],
    selectedUser: string,
    selectedCompany: string,
    isLoadingAction: boolean,
    openModalExportExcel: boolean,
}

type Props =
    & ComponentProps
    & typeof EmployeeMasterStore.actionCreators
    & typeof CompanyMasterStore.actionCreators;

interface SelectorOptions {
    label: string,
    value: string
}

class LogUserFolderAuthorization extends React.Component<Props, State> {
    static contextType: React.Context<any> | undefined = AdminContext;

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            folderAuthUserLog: [],
            userOptions: [],
            companyOptions: [],
            userRole: [],
            selectedCompany: "",
            selectedUser: "",
            isLoadingAction: false,
            openModalExportExcel: false,
        }

        this.openModalExportExcel = this.openModalExportExcel.bind(this);
        this.toggleIsLoadingAction = this.toggleIsLoadingAction.bind(this);
    }

    public componentDidMount() {
        this.props.requestFunction(0, "GET", { id: '', list_company: [] }, false, null, "", "", "", '', '');
        this.props.requestFunctionCompanyMaster(true, "GET", "", "", "");
    }

    public componentDidUpdate() {
        const isLoadingLocal = this.state.isLoadingAction;
        const { userRole }: AdminContextType = this.context;

        if (isLoadingLocal) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }

        if (userRole.length > 0 && this.state.userRole.length === 0) {
            this.setState({
                userRole
            });
        } else {
            this.handleSetDisplayCompany(this.state.userRole);
        }
    }

    public handleSetDisplayCompany(userRoleAccess: UserRoleAccessModel[]) {
        const { displayCompany } = this.props.company;
        const masterSid = userRoleAccess.find(role => role.sid === '*');

        if (displayCompany && masterSid && this.state.companyOptions.length === 0) {
            this.setState({
                companyOptions: displayCompany
            });
        } else if (displayCompany && userRoleAccess.length > 0 && this.state.companyOptions.length === 0) {
            const roleSid = userRoleAccess.map(role => role.sid);

            this.setState({
                companyOptions: displayCompany.filter(company => roleSid.includes(company.sid)),
            });
        }
    }

    public formatRFC3339ToDMY(dmy: string) {
        if (dmy !== undefined && dmy !== "" && dmy !== 'null') {
            var year = dmy.substring(0, 4);
            var day = dmy.substring(8, 10);
            var month = dmy.substring(5, 7);
            var Datevalue = "";
            var datevalue = "";
            var UTC = new Date()
            if (dmy.length < 9) {
                datevalue = day + '-' + month + '-' + year

            } else {
                var times = dmy.substring(11, 19);
                datevalue = day + '-' + month + '-' + year + ' ' + times;
            }
            return datevalue;
        } else {
            Datevalue = "";

            return Datevalue;
        }
    }

    public formatAuthIdToString(authId: string): string {
        const auth = DefaultRoleAuthorize.find(auth => auth.value === authId);

        return (auth) ? auth.label : "";
    }

    public handleSelectCompany(company: DisplayCompany) {
        this.toggleIsLoadingAction();

        //console.log("[Debug] company", company)
        this.setState({ selectedCompany: company.shortName });

        const sid = this.findSidFromCompany(company.label);
        if (sid) {
            this.filterUserBySid(sid);
        }

        setTimeout(() => this.toggleIsLoadingAction(), 0);
    }

    public handleSelectUser(user: SelectorOptions) {
        this.toggleIsLoadingAction();

        //console.log("[Debug] user", user)
        this.setState({ selectedUser: user.value });

        setTimeout(() => this.toggleIsLoadingAction(), 0);
    }

    public searchLogClick(user: string, company: string) {
        this.setState({ isLoading: true });
        this.getFolderAuthUserLog("", user, company);
    }

    public toggleIsLoadingAction(): void {
        this.setState({ isLoadingAction: !this.state.isLoadingAction });
    }

    public openModalExportExcel(isOpen: boolean): void {
        this.setState({
            openModalExportExcel: isOpen,
        });
    }

    private filterUserBySid(companySid: string) {
        const empResultList = this.props.employee.responseEmployee?.result_list;

        if (empResultList) {
            const userInCompany = empResultList.filter(emp => {
                const foundCompany = emp.list_company.find(empCompany => companySid === empCompany.sid);

                return (foundCompany) ? true : false;
            });


            const foundUserInCompany: DisplayEmployee[] = [];
            //foundUserInCompany.push({ label: 'select all user', value: '*' });
            userInCompany.map(user => foundUserInCompany.push({ label: user.id, value: user.id }));

            this.setState({
                userOptions: foundUserInCompany,
            });
        }
    }

    private findSidFromCompany(companyName: string): string | undefined {
        const companyResultList = this.props.company.responseCompanyMaster?.result_list;

        if (companyResultList === undefined) return;

        const sid = companyResultList
            .filter(result => result.company_name === companyName)
            .map(company => company.sid)[0];

        return sid;
    }

    private async getFolderAuthUserLog(sid: string, username: string, company: string) {
        const response = await fetch(`/v1/folder-auth-user/logging?app_id=${sid}&username=${username}&company=${company}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                'Content-Type': 'application/json'
            },
        });

        try {
            const { message, result_list } = await response.json() as FolderAuthUserLoggingResponse;

            if (!response.ok) {
                throw new Error(message);
            }

            //const displayUser = result_list.map(result => ({ label: result.username, value: result.username } as SelectorOptions));
            //const userOptions = displayUser.reduce<SelectorOptions[]>((acc, current) => {
            //    const foundSameLabel = acc.find(item => item.label === current.label);
            //    if (!foundSameLabel) {
            //        return acc.concat([current]);
            //    } else {
            //        return acc;
            //    }
            //}, []);

            this.setState({
                isLoading: false,
                folderAuthUserLog: result_list,
            });

        } catch (error) {
            Swal.fire(
                'Error',
                `${(error as any).message}`,
                'error'
            )
        }
    }

    render() {
        const { isLoading, folderAuthUserLog, userOptions, companyOptions, selectedUser, selectedCompany } = this.state;

        return (
            <>
                <div style={{ backgroundColor: '#ffffff' }}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header className='h-50 d-inline-block' style={{ marginBottom: '-12px', marginTop: '-12px' }}>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                        <Form.Label column sm="3">ค้นหาข้อมูล</Form.Label>
                                        <Button
                                            size="sm"
                                            className="btn-export-excel mr-2"
                                            onClick={() => this.openModalExportExcel(true)}>
                                            Export Excel
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Card.Body style={{ overflow: 'none' }}>
                                    <Row className="mt-2">
                                        <Col xs={6}>
                                            <Form className="mb-3" as={Row}>
                                                <Form.Label column sm={3} className="text-center">Company :</Form.Label>
                                                <Form.Group controlId="company" style={{ width: '500px' }}>
                                                    <CreatableSelect
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={(company) => this.handleSelectCompany(company)}
                                                        options={companyOptions}
                                                        isValidNewOption={() => false}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col xs={6}>
                                            <Form className="mb-3" as={Row}>
                                                <Form.Label column sm="3" className="text-center">User :</Form.Label>
                                                <Form.Group controlId="company" style={{ width: '500px' }}>
                                                    <CreatableSelect
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={(user) => this.handleSelectUser(user)}
                                                        options={userOptions}
                                                        isValidNewOption={() => false}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                style={{ marginBottom: '10px', marginRight: '10px', float: 'right' }}
                                                variant="outline-success"
                                                size="sm"
                                                onClick={() => this.searchLogClick(selectedUser, selectedCompany)} >
                                                <FaSearch />&nbsp;ค้นหา
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <table className='table table-bordered mt-3'>
                        <thead className="thead-light">
                            <tr>
                                <th style={{ width: '10%' }}>Action By</th>
                                <th style={{ width: '10%' }}>Log Action</th>
                                <th style={{ width: '25%' }}>Old Role ID</th>
                                <th style={{ width: '25%' }}>New Role ID</th>
                                <th style={{ width: '10%' }}>Action On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(isLoading)
                                ? (
                                    <tr>
                                        <td colSpan={8} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                            <Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...
                                        </td>
                                    </tr>
                                )
                                : (!isLoading && folderAuthUserLog && folderAuthUserLog.length > 0)
                                    ? folderAuthUserLog.map((log, index) => (
                                        <tr key={index}>
                                            <td scope="row">{log.username}</td>
                                            <td scope="row">{log.action_log}</td>
                                            <td scope="row">
                                                <div>
                                                    <div>
                                                        {log.old_full_path || ''}
                                                    </div>
                                                    <div className="d-flex mt-2">
                                                        {(log.old_role_id && log.old_role_id !== "")
                                                            ? log.old_role_id
                                                                .split("_ID")[1]
                                                                .split('|')
                                                                .map((auth, index) => {
                                                                    const badgeStyle = "mr-2 badge";
                                                                    const badgeColor = (log.action_log === "Delete")
                                                                        ? `${badgeStyle} badge-danger`
                                                                        : `${badgeStyle} badge-primary`;
                                                                    return (
                                                                        <span key={index} className={badgeColor}>
                                                                            {this.formatAuthIdToString(auth)}
                                                                        </span>
                                                                    )
                                                                })
                                                            : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                            <td scope="row">
                                                <div>
                                                    <div>
                                                        {log.new_full_path || ''}
                                                    </div>
                                                    <div className="d-flex mt-2">
                                                        {(log.new_role_id && log.new_role_id !== "")
                                                            ? log.new_role_id
                                                                .split("_ID")[1]
                                                                .split('|')
                                                                .map((auth, index) => (
                                                                    <span key={index} className="badge badge-success mr-2">
                                                                        {this.formatAuthIdToString(auth)}
                                                                    </span>
                                                                ))
                                                            : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                            <td scope="row">{this.formatRFC3339ToDMY(String(log.created_on))}</td>
                                        </tr>
                                    ))
                                    : (
                                        <tr>
                                            <td colSpan={8} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                                &nbsp;&nbsp;No Log...
                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>

                {this.state.openModalExportExcel &&
                    <ExportExcelLogModal
                    openModalExportExcel={this.openModalExportExcel}
                    isOpen={this.state.openModalExportExcel}
                    companyOptions={this.state.companyOptions}
                    responseCompanyMaster={this.props.company.responseCompanyMaster}
                    responseEmployee={this.props.employee.responseEmployee}
                    displayEmployee={this.props.employee.displayEmployee}
                    loadingAction={this.toggleIsLoadingAction}
                    />
                }
            </>
        )
    }
}

export default connect(
    (state: ApplicationState, ownProps) => ({
        ...ownProps,
        employee: state.employee,
        company: state.companyMaster
    }),
    {
        ...EmployeeMasterStore.actionCreators,
        ...CompanyMasterStore.actionCreators
    }
)(LogUserFolderAuthorization as any);