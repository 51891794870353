import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Model from '../models/Folder';

interface RequestFunctionAction {
    type: 'REQUEST_FOLDERMANAGE';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_FOLDERMANAGE';
    model?: Model.ResponseFolder;
    isSuccess: boolean;
}

interface ClearFunctionAction {
    type: 'CLEAR_FOLDERMANAGE';
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ClearFunctionAction

export const actionCreators = {
    requestFunctionFolder: (method: string, body: Model.Folder): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.folder && method === "GET") {
            fetch(`/v1/folder?sid=` + body.sid + `&companyCode=` + body.company_code + `&nodeCode=` + body.node_code, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
            })
                .then(response => {
                    return response.json() as Promise<Model.ResponseFolder>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: data, isSuccess: true });
                })
                .catch(error => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: undefined, isSuccess: false });
                });

            dispatch({ type: 'REQUEST_FOLDERMANAGE' });
        }

        if (appState && appState.folder && method === "ADD") {

            fetch(`/v1/folder/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
                body: JSON.stringify(body)
            })
                .then(response => {
                    return response.json() as Promise<Model.ResponseFolder>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: data, isSuccess: true });
                })
                .catch(error => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: undefined, isSuccess: false });
                });

            dispatch({ type: 'REQUEST_FOLDERMANAGE' });
        }

        if (appState && appState.folder && method === "EDIT") {
            fetch(`/v1/folder/edit`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
                body: JSON.stringify(body)
            })
                .then(response => {
                    return response.json() as Promise<Model.ResponseFolder>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: data, isSuccess: true });
                })
                .catch(error => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: undefined, isSuccess: false });
                });

            dispatch({ type: 'REQUEST_FOLDERMANAGE' });
        }

        if (appState && appState.folder && method === "RENAME") {

            fetch(`/v1/folder/rename`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
                body: JSON.stringify(body)
            })
                .then(response => {
                    return response.json() as Promise<Model.ResponseFolder>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: data, isSuccess: true });
                })
                .catch(error => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: undefined, isSuccess: false });
                });

            dispatch({ type: 'REQUEST_FOLDERMANAGE' });
        }

        if (appState && appState.folder && method === "DELETE") {

            fetch(`/v1/folder/delete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
                body: JSON.stringify(body)
            })
                .then(response => {
                    return response.json() as Promise<Model.ResponseFolder>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: data, isSuccess: true });
                })
                .catch(error => {
                    dispatch({ type: 'RECEIVE_FOLDERMANAGE', model: undefined, isSuccess: false });
                });

            dispatch({ type: 'REQUEST_FOLDERMANAGE' });
        }

        if (appState && appState.folder && method === "CLEAR") {
            dispatch({ type: 'CLEAR_FOLDERMANAGE' });
        }
    }
};

const unloadedState: Model.FolderState = { isLoading: false, response: undefined, isSuccess: undefined };

export const reducer: Reducer<Model.FolderState> = (state: Model.FolderState | undefined, incomingAction: Action): Model.FolderState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    console.log(action.type)
    switch (action.type) {
        case 'REQUEST_FOLDERMANAGE':
            return {
                isLoading: true,
                response: undefined,
                isSuccess: undefined,
            };
        case 'RECEIVE_FOLDERMANAGE':
            return {
                isLoading: false,
                response: action.model,
                isSuccess: action.isSuccess
            };
        case 'CLEAR_FOLDERMANAGE':
            return {
                isLoading: false,
                response: undefined,
                isSuccess: undefined,
            };

        default: return state;
    }
};