import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as File from '../models/File';

interface RequestFunctionAction {
    type: 'REQUEST_FILE';
    version: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_FILE';
    model: File.ResponseFile;
    version: number;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction
let controller: AbortController | null = null;

export const actionCreators = {
    requestFunction: (pageload: boolean, key: string, isSearch: boolean, bucket: string, version: number, page: number, page_size: number, list_company: string[], fileName: string = ''): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (controller !== null) {
            controller.abort();
        }

        if (isSearch && (key === null || key === "" || key === " ")) {
            console.log("if dispatch REQUEST_FILE");
            dispatch({ type: 'REQUEST_FILE', version: version + 1 });
        } else {
            var company = ""
            if (list_company && list_company.length !== 0 && list_company !== undefined) {
                for (var i = 0; i < list_company.length; i++) {
                    company += "&app_id=" + list_company[i]
                }
            }

            if (appState && appState.file && !isSearch) {

                var query = "";
                var queryBucket = "";
                if (typeof bucket !== 'undefined' && bucket) {
                    queryBucket = "bucket:(" + bucket + ")"
                }

                var queryKey = ""
                if (typeof key !== 'undefined' && key) {
                    queryKey = " AND " + key
                }

                query = queryBucket + queryKey

                const currController = new AbortController();
                const signal = currController.signal;
                controller = currController;

                fetch('/v3/dms?query=' + query + '&page=' + page + '&page_size=' + page_size + '&file_name=' + fileName + company, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    },
                    signal
                })
                    .then(response => {
                        return response.json() as Promise<File.ResponseFile>
                    })
                    .then(data => {
                        console.log('ngame store', data);
                        dispatch({ type: 'RECEIVE_FILE', model: data, version: version + 2 });
                    })
                    .catch(error => {
                        dispatch({ type: 'REQUEST_FILE', version: version + 1 });
                    });
            }

            if (appState && appState.file && pageload === true && isSearch) {

                const currController = new AbortController();
                const signal = currController.signal;
                controller = currController;

                fetch(`/v3/dms?query=` + key + `&page=1&page_size=10` + company, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    },
                    signal
                })
                    .then(response => {
                        return response.json() as Promise<File.ResponseFile>
                    })
                    .then(data => {
                        dispatch({ type: 'RECEIVE_FILE', model: data, version: version + 3 });
                    })
                    .catch(error => {
                        dispatch({ type: 'REQUEST_FILE', version: version + 1 });
                    });
            }
        }
    }
};

const unloadedState: File.FileState = { isLoading: false, response: undefined, version: 0 };

export const reducer: Reducer<File.FileState> = (state: File.FileState | undefined, incomingAction: Action): File.FileState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_FILE':
            return {
                isLoading: false,
                version: action.version
            };
        case 'RECEIVE_FILE':
            return {
                isLoading: true,
                response: action.model,
                version: action.version
            };

        default: return state;
    }
};