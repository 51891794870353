import './common.css';

import React, { CSSProperties } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

type OnClickPageType = (
    event: React.MouseEvent<HTMLButtonElement>,
    page: number
) => void;

interface ComponentProps {
    page?: number;
    pageSize?: number;
    disabledNext?: boolean;
    style?: CSSProperties;
    onChangePage?: OnClickPageType;
}

type Props = ComponentProps;

function InfinitePagination(props: Props) {
    const emptyFunction: OnClickPageType = () => undefined;

    const {
        page = 1,
        pageSize = 25,
        disabledNext = false,
        style,
        onChangePage = emptyFunction,
    } = props;

    return (
        <ul
            style={style}
            className="infinite-pagination-container"
        >
            <li>
                <button
                    disabled={page <= 1}
                    onClick={(event) => onChangePage(event, page - 1)}
                    className="infinite-pagination-button"
                >
                    <FaChevronLeft />
                </button>
            </li>
            <li>...</li>
            <li>
                <button className="infinite-pagination-button active">
                    {page}
                </button>
            </li>
            <li>...</li>
            <li>
                <button
                    disabled={disabledNext}
                    onClick={(event) => onChangePage(event, page + 1)}
                    className="infinite-pagination-button"
                >
                    <FaChevronRight />
                </button>
            </li>
        </ul>
    );
}

export default InfinitePagination;
